import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Price from './Price';

const getImagePath = async (product) => {
  const { default: path } = await import(
    `../../images/products/${product.images_folder}/${product.model}/0.jpg`
  );
  return path;
};

const OrderItem = ({ item }) => {
  let { product, quantity } = item;
  const calcDiscountedPrice = () => {
    if (product?.discount?.percent) {
      return product.price - product.price * product?.discount?.percent;
    } else if (product?.discount?.amount) {
      return product.price - product?.discount?.amount;
    } else {
      return product.price;
    }
  };

  let [imagePath, setImagePath] = useState(null);

  useEffect(() => {
    getImagePath(product).then(setImagePath);
  }, [product]);

  return (
    <div className='orderItem'>
      <img src={imagePath} alt='' />
      <div className='orderQuantity'>
        <div className='rhombus'></div>
        <small>{quantity}</small>
      </div>
      <p className='productName'>
        <Link to={`../store/product/${item.product.id}`}>{product.name}</Link>
        <small>
          {product.model} - {product.size}
        </small>
      </p>
      <Price
        className='productTotal'
        amount={calcDiscountedPrice() * quantity}
      />
    </div>
  );
};

export default OrderItem;
