import React, { useState } from 'react';
import Logo from '../components/Logo';
import Menu from './Menu';

const Header = () => {
  const [isOpen, setIsOpen] = useState(false);
  const toggleMenu = () => setIsOpen(!isOpen);

  return (
    <header className={`header`}>
      <Logo />
      <p className='header__openMenu' onClick={toggleMenu}>
        &#9776;
      </p>
      <div className={`header__menu ${isOpen && 'open'}`}>
        <p className='header__closeMenu' onClick={toggleMenu}>
          &times;
        </p>
        <div onClick={toggleMenu}>
          <Menu />
        </div>
      </div>
      {/* {user && <AccountLink />}
      {!user && <AuthenticationLinks />} */}
    </header>
  );
};

export default Header;
