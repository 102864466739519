export const calcDiscountedPrice = (element) => {
  if (element?.discount?.percent) {
    return element.price - element.price * element?.discount?.percent;
  } else if (element?.discount?.amount) {
    return element.price - element?.discount?.amount;
  } else {
    return Number(element.price);
  }
};

export const trimValue = (event) => {
  event.target.value = event.target.value.trim();
};
