import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
// import { FcGoogle } from 'react-icons/fc';

// import { useGoogleLogin } from '@react-oauth/google';

import { Link } from 'react-router-dom';
import { login } from '../../../redux/actions/userActions';
import { emailRegExp } from '../../../constants/regExp';
import { trimValue } from '../../store/utils';

const LoginForm = ({ send: setFormSent }) => {
  const dispatch = useDispatch();
  const { loading, error } = useSelector((state) => state.userLogin);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onSubmit = async ({ email, password, rememberMe }) => {
    dispatch(login(email, password, rememberMe));
    if (setFormSent) {
      setFormSent(true);
    }
  };

  // const googleSuccess = (response) => {
  //   console.log(response);
  //   dispatch(googleLogin(response));
  // };
  // const googleError = (error) => {
  //   console.log(error);
  // };
  // const loginGoogle = useGoogleLogin({
  //   onSuccess: (tokenResponse) => googleSuccess(tokenResponse),
  //   onError: (error) => googleError(error),
  //   onNonOAuthError: (error) => googleError(error),
  //   flow: 'auth-code',
  // });
  return (
    <section className='loginForm'>
      {/* <div className='socialLogin'>
        <button className='google' onClick={() => loginGoogle()}>
          <FcGoogle className='icon' />
        </button>
      </div> */}

      <form onSubmit={handleSubmit(onSubmit)}>
        {/* Errors */}

        {loading && <div className='spinner' />}
        <section>{error && <p className='error'>{error}</p>}</section>

        <section className={`${errors.email ? 'wrong' : ''}`}>
          <input
            type='text'
            {...register('email', {
              pattern: {
                value: emailRegExp,
                message: 'Email non valida',
              },
              maxLength: { value: 150, message: 'Email troppo lunga' },
              required: 'Campo obbligatorio',
            })}
            id='email'
            autoComplete='off'
            placeholder=' '
            onChange={(e) => trimValue(e)}
          />
          <label htmlFor='email'>Email</label>

          {errors.email && <p className='error'>{errors.email.message}</p>}
        </section>

        {/* Password */}
        <section className={`${errors.password ? 'wrong' : ''}`}>
          <input
            type='password'
            {...register('password', {
              required: 'Campo obbligatorio',
              minLength: { value: 8, message: 'Password troppo corta' },
            })}
            id='password'
            autoComplete='off'
            placeholder=' '
          />
          <label htmlFor='password'>Password</label>

          {errors.password && (
            <p className='error'>{errors.password.message}</p>
          )}
          <Link to='../auth/restore_password' className='forgotPassword'>
            Password dimenticata?
          </Link>
        </section>

        <input type='submit' value='Accedi' className='btn' />
      </form>
    </section>
  );
};

export default LoginForm;
