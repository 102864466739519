// Store
export const STORE_REQUEST = 'STORE_REQUEST';
export const STORE_SUCCESS = 'STORE_SUCCESS';
export const STORE_FAILED = 'STORE_FAILED';

// Store
export const GET_CART_REQUEST = 'GET_CART_REQUEST';
export const GET_CART_SUCCESS = 'GET_CART_SUCCESS';
export const GET_CART_FAILED = 'GET_CART_FAILED';

// Get Product
export const GET_PRODUCT_REQUEST = 'GET_PRODUCT_REQUEST';
export const GET_PRODUCT_SUCCESS = 'GET_PRODUCT_SUCCESS';
export const GET_PRODUCT_FAILED = 'GET_PRODUCT_FAILED';

// Add Product to Cart
export const ADD_PRODUCT_TO_CART_REQUEST = 'ADD_PRODUCT_TO_CART_REQUEST';
export const ADD_PRODUCT_TO_CART_SUCCESS = 'ADD_PRODUCT_TO_CART_SUCCESS';
export const ADD_PRODUCT_TO_CART_FAILED = 'ADD_PRODUCT_TO_CART_FAILED';

// Reduce Product Quantity from Cart
export const REDUCE_PRODUCT_FROM_CART_REQUEST =
  'REDUCE_PRODUCT_FROM_CART_REQUEST';
export const REDUCE_PRODUCT_FROM_CART_SUCCESS =
  'REDUCE_PRODUCT_FROM_CART_SUCCESS';
export const REDUCE_PRODUCT_FROM_CART_FAILED =
  'REDUCE_PRODUCT_FROM_CART_FAILED';

// Remove Product to Cart
export const REMOVE_PRODUCT_FROM_CART_REQUEST =
  'REMOVE_PRODUCT_FROM_CART_REQUEST';
export const REMOVE_PRODUCT_FROM_CART_SUCCESS =
  'REMOVE_PRODUCT_FROM_CART_SUCCESS';
export const REMOVE_PRODUCT_FROM_CART_FAILED =
  'REMOVE_PRODUCT_FROM_CART_FAILED';

// Get order from Checkout
export const GET_ORDER_REQUEST = 'GET_ORDER_REQUEST';
export const GET_ORDER_SUCCESS = 'GET_ORDER_SUCCESS';
export const GET_ORDER_FAILED = 'GET_ORDER_FAILED';

// Apply coupon for Checkout
export const APPLY_DISCOUNT_REQUEST = 'APPLY_DISCOUNT_REQUEST';
export const APPLY_DISCOUNT_SUCCESS = 'APPLY_DISCOUNT_SUCCESS';
export const APPLY_DISCOUNT_FAILED = 'APPLY_DISCOUNT_FAILED';

// Remove coupon in Checkout
export const REMOVE_COUPON_REQUEST = 'REMOVE_COUPON_REQUEST';
export const REMOVE_COUPON_SUCCESS = 'REMOVE_COUPON_SUCCESS';
export const REMOVE_COUPON_FAILED = 'REMOVE_COUPON_FAILED';

// Send Info for Checkout
export const SEND_EMAIL_REQUEST = 'SEND_EMAIL_REQUEST';
export const SEND_EMAIL_SUCCESS = 'SEND_EMAIL_SUCCESS';
export const SEND_EMAIL_FAILED = 'SEND_EMAIL_FAILED';

// Send Shipping info for Checkout
export const SEND_SHIPPING_REQUEST = 'SEND_SHIPPING_REQUEST';
export const SEND_SHIPPING_SUCCESS = 'SEND_SHIPPING_SUCCESS';
export const SEND_SHIPPING_FAILED = 'SEND_SHIPPING_FAILED';

// Send Invoice for Checkout
export const SEND_INVOICE_REQUEST = 'SEND_INVOICE_REQUEST';
export const SEND_INVOICE_SUCCESS = 'SEND_INVOICE_SUCCESS';
export const SEND_INVOICE_FAILED = 'SEND_INVOICE_FAILED';

// Get Payment Intent for Checkout
export const GET_PAYMENT_INTENT_REQUEST = 'GET_PAYMENT_INTENT_REQUEST';
export const GET_PAYMENT_INTENT_SUCCESS = 'GET_PAYMENT_INTENT_SUCCESS';
export const GET_PAYMENT_INTENT_FAILED = 'GET_PAYMENT_INTENT_FAILED';

// Confirm Payment after Stripe result
export const CONFIRM_PAYMENT_REQUEST = 'CONFIRM_PAYMENT_REQUEST';
export const CONFIRM_PAYMENT_SUCCESS = 'CONFIRM_PAYMENT_SUCCESS';
export const CONFIRM_PAYMENT_FAILED = 'CONFIRM_PAYMENT_FAILED';
