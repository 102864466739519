import React from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { updateProfile } from '../../../redux/actions/userActions';
import { emailRegExp, phoneRegExp } from '../../../constants/regExp';

const Profile = ({ user }) => {
  const dispatch = useDispatch();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onSubmit = async (formData) => {
    dispatch(updateProfile(formData));
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      {/* First Name */}
      <section className={`${errors.firstName ? 'wrong' : ''}`}>
        <input
          type='text'
          {...register('firstName', {
            value: user.first_name,
            maxLength: { value: 150, message: 'Cognome troppo lungo' },
            required: 'Campo obbligatorio',
          })}
          placeholder=' '
          id='firstName'
        />
        <label htmlFor='firstName'>Nome</label>

        {errors.firstName && (
          <p className='error'>{errors.firstName.message}</p>
        )}
      </section>
      {/* Last Name */}
      <section className={`${errors.lastName ? 'wrong' : ''}`}>
        <input
          type='text'
          {...register('lastName', {
            value: user.last_name,
            maxLength: { value: 50, message: 'Cognome troppo lungo' },
            required: 'Campo obbligatorio',
          })}
          id='lastName'
          placeholder=' '
        />
        <label htmlFor='lastName'>Cognome</label>

        {errors.lastName && <p className='error'>{errors.lastName.message}</p>}
      </section>

      {/* Email */}
      <section className={`${errors.email ? 'wrong' : ''}`}>
        <input
          type='text'
          {...register('email', {
            value: user.email,
            pattern: {
              value: emailRegExp,
              message: 'Email non valida',
            },
            maxLength: { value: 150, message: 'Email troppo lunga' },
            required: 'Campo obbligatorio',
          })}
          id='email'
          placeholder=' '
        />
        <label htmlFor='email'>Email</label>
        {errors.email && <p className='error'>{errors.email.message}</p>}
      </section>

      {/* Phone */}
      <section className={`${errors.phone ? 'wrong' : ''}`}>
        <input
          type='text'
          {...register('phone', {
            value: user.phone,
            pattern: {
              value: phoneRegExp,
              message: 'Numero non valido',
            },
            maxLength: { value: 15, message: 'Numero troppo lungo' },
            required: 'Campo obbligatorio',
          })}
          id='phone'
          placeholder=' '
        />
        {errors.phone && <p className='error'>{errors.phone.message}</p>}
        <label htmlFor='phone'>Numero di telefono</label>
      </section>

      <input type='submit' value='Aggiorna' className='btn' />
    </form>
  );
};

export default Profile;
