import React, { useEffect, useState } from 'react';
import { Navigate } from 'react-router-dom';
import Price from './Price';
import { useDispatch, useSelector } from 'react-redux';
import Protected from '../Protected';
import { getUserDetails } from '../../redux/actions/userActions';

const TicketOrderItem = ({ ticket, where }) => {
  const { user, loading: userLoading } = useSelector(
    (state) => state.userDetails
  );
  const { loading } = useSelector((state) => state.cart);
  const { order } = useSelector((state) => state.order);
  const dispatch = useDispatch();
  const { insurance } = useSelector((state) => state.createInsurance);

  useEffect(() => {
    if (where === 'checkout') {
      dispatch(getUserDetails());
    }
  }, [where, dispatch]);

  const IMAGES = {
    Nord: () => import(`../../images/tickets/miniatura_Nord.jpg`),
    Centro: () => import(`../../images/tickets/miniatura_Centro.jpg`),
    Sud: () => import(`../../images/tickets/miniatura_Sud.jpg`),
    Assoluto: () => import(`../../images/tickets/miniatura_Assoluto.jpg`),
  };

  const [tappaImage, setTappaImage] = useState(null);

  useEffect(() => {
    const loadTappaImage = async () => {
      const imageImport = IMAGES[ticket.tappa];
      if (imageImport) {
        const imageModule = await imageImport();
        setTappaImage(imageModule.default);
      }
    };
    loadTappaImage();
  });

  const checkInsurance = () => {
    for (let i = 0; i < order?.cart?.tickets?.length; i++) {
      if (order.cart.tickets[i].insurance) {
        return true;
      }
    }
    return false;
  };

  return (
    <>
      {where === 'checkout' && <Protected />}
      {where === 'checkout' &&
        ticket?.needs_athlete_info &&
        !ticket?.athlete_info && (
          <Navigate to={`../store/ticket/${ticket.event_id}/athlete_info/`} />
        )}
      {where === 'checkout' &&
        ticket.needs_insurance &&
        !user?.insurance &&
        !userLoading &&
        !ticket.insurance &&
        !loading &&
        !insurance &&
        !checkInsurance() && <Navigate to='../account/insurance' />}
      <section className='orderItem ticket'>
        <img src={tappaImage} alt='' />
        <p className='productName'>
          Tappa {ticket.tappa}
          <small>Biglietto</small>
          {ticket.insurance && (
            <>
              <br />
              <small>+ assicurazione</small>
            </>
          )}
        </p>

        {ticket.insurance && !loading ? (
          <Price amount={ticket.price + 30} className='productTotal' />
        ) : (
          <Price amount={ticket.price} className='productTotal' />
        )}
      </section>
    </>
  );
};

export default TicketOrderItem;
