import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { getOrder, sendEmailCheckout } from '../../redux/actions/storeActions';
import { useDispatch, useSelector } from 'react-redux';
import { Link, Navigate } from 'react-router-dom';
import { emailRegExp } from '../../constants/regExp';
import { BsArrowLeft } from 'react-icons/bs';
import { trimValue } from './utils';

const CostumerForm = () => {
  const { order, error } = useSelector((state) => state.order);
  const { user } = useSelector((state) => state.userLogin);
  let [formSent, setFormSent] = useState(false);

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getOrder());
  }, [dispatch]);

  useEffect(() => {
    reset({
      email: order?.email,
    });
  }, [order?.email, reset]);

  const onSubmit = (formData) => {
    dispatch(sendEmailCheckout(formData));
    setFormSent(true);
  };

  return (
    <section className='anonStep'>
      {formSent && order?.email && <Navigate to={'../shipping'} />}
      {user && <Navigate to={'../shipping'} />}
      <h2>Procedi come ospite</h2>
      <small>inserisci la tua email per ricevere la conferma d’ordine</small>
      <form onSubmit={handleSubmit(onSubmit)}>
        {error && <p className='error'>{error}</p>}
        <section>
          <input
            id='email'
            {...register('email', {
              required: 'Campo obbligatorio',
              pattern: {
                value: emailRegExp,
                message: 'Email non valida',
              },
            })}
            type='email'
            placeholder=' '
            onChange={(e) => trimValue(e)}
          />
          <label htmlFor='email'>Email</label>
          {errors.email && <span role='alert'>{errors.email.message}</span>}
        </section>

        <section className='checkbox'>
          <input
            type='checkbox'
            {...register('terms', {
              required: 'Per procedere devi accettare Termini e condizioni',
            })}
            id='terms'
            placeholder=' '
          />
          <label htmlFor='terms' id='termsLabel' className='checkboxLabel'>
            Accetto{' '}
            <Link
              to='../docs/terms'
              title='terms'
              target='_blank'
              rel='noreferrer'>
              Termini e condizioni
            </Link>
          </label>
          {errors.terms && <p className='error'>{errors.terms.message}</p>}
        </section>
        <input type='submit' value='Procedi' className='btn' />
      </form>
      <Link to='../' className='anonLink'>
        <BsArrowLeft />
      </Link>
    </section>
  );
};

export default CostumerForm;
