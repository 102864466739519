import React from 'react';
import Title from '../../components/Title';
import Summary from '../../layout/store/Summary';
import CheckoutInfo from '../../layout/store/CheckoutInfo';

const Checkout = () => {
  return (
    <>
      <Title title='Checkout' />
      <main className='checkoutPage'>
        <h1>Checkout</h1>
        <div className='content'>
          <Summary />
          <CheckoutInfo />
        </div>
      </main>
    </>
  );
};

export default Checkout;
