import React, { useEffect } from 'react';

const Title = ({ title }) => {
  useEffect(() => {
    document.title = `${title} | Resistance`;
  }, [title]);
  return <></>;
};

export default Title;
