import {
  PaymentElement,
  useElements,
  useStripe,
} from '@stripe/react-stripe-js';
import { DEBUG } from '../..';
import { useState } from 'react';

const StripePaymentForm = () => {
  const stripe = useStripe();
  const elements = useElements();
  let [error, setError] = useState(null);

  const handleSubmit = async (event) => {
    // We don't want to let default form submission happen here,
    // which would refresh the page.
    event.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js hasn't yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    const result = await stripe.confirmPayment({
      //`Elements` instance that was used to create the Payment Element
      elements,
      confirmParams: {
        return_url: DEBUG
          ? 'http://localhost:3000/#/store/confirmed'
          : 'https://www.resistanceitalia.com/#/store/confirmed',
        queryParameters: {},
      },
    });

    if (result.error) {
      // Show error to your customer (for example, payment details incomplete)
      setError(result.error.message);
    } else {
      // Your customer will be redirected to your `return_url`. For some payment
      // methods like iDEAL, your customer will be redirected to an intermediate
      // site first to authorize the payment, then redirected to the `return_url`.
    }
  };
  return (
    <form onSubmit={handleSubmit}>
      {error && <p className='error'>{error}</p>}
      <PaymentElement />
      <button className='btn' id='paymentBtn' disabled={!stripe}>
        Procedi
      </button>
    </form>
  );
};

export default StripePaymentForm;
