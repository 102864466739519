import React from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';

import { restorePasswordRequest } from '../../redux/actions/userActions';
import Title from '../../components/Title';
import { emailRegExp } from '../../constants/regExp';
import { trimValue } from '../../components/store/utils';

const RestorePwd = () => {
  const dispatch = useDispatch();
  const { loading, error, message } = useSelector(
    (state) => state.restorePassword
  );
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();

  const onSubmit = async ({ email }) => {
    reset();
    dispatch(restorePasswordRequest(email));
  };

  return (
    <>
      <Title title='Recupera Password' />
      {loading && <div className='spinner'></div>}
      <main className='authContainer'>
        <form onSubmit={handleSubmit(onSubmit)}>
          <section className='title'>
            <h2>Recupera Password</h2>
            <small>Controlla l'email e segui le istruizioni</small>
          </section>

          {/* Errors */}
          <section>
            {error && (
              <p className='error'>
                C'è stato un errore. Assicurati che l'email sia corretta
              </p>
            )}
          </section>

          {/* Message */}
          <section>
            {message?.status === 'OK' && (
              <p className='message'>Email inviata</p>
            )}
          </section>

          {/* Email */}
          <section className={`${errors.email ? 'wrong' : ''}`}>
            <input
              type='text'
              {...register('email', {
                pattern: {
                  value: emailRegExp,
                  message: 'Fornisci una email valida',
                },
                maxLength: { value: 150, message: 'Email troppo lunga' },
                required: 'Campo obbligatorio',
              })}
              id='email'
              placeholder=' '
              className={`${errors.email ? 'wrong' : ''}`}
              onChange={(e) => trimValue(e)}
            />
            <label htmlFor='email'>Email</label>
            {errors.email && <p className='error'>{errors.email.message}</p>}
          </section>
          <input type='submit' value='Invia' className='btn' />
        </form>
      </main>
    </>
  );
};

export default RestorePwd;
