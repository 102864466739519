import React from 'react';
import Title from '../../components/Title';
import proCardFronte from '../../images/proCards/ProCardFronte.jpg';
import proCardRetro from '../../images/proCards/ProCardRetro.jpg';
import proCard_Nord2024_1 from '../../images/proCards/Nord2024/Vincenzo.jpg';
import proCard_Nord2024_2 from '../../images/proCards/Nord2024/Filippo.jpg';
import proCard_Nord2024_3 from '../../images/proCards/Nord2024/Alessandro.jpg';
import proCard_Nord2024_4 from '../../images/proCards/Nord2024/Luca.jpg';
import proCard_Nord2024_5 from '../../images/proCards/Nord2024/Riccardo.jpg';
import proCard_Nord2024_6 from '../../images/proCards/Nord2024/Simone.jpg';
import proCard_Nord2024_7 from '../../images/proCards/Nord2024/Samuele.jpg';
import proCard_Nord2024_8 from '../../images/proCards/Nord2024/Dennis.jpg';
import proCard_Nord2024_9 from '../../images/proCards/Nord2024/Sergio.jpg';
import proCard_Nord2024_10 from '../../images/proCards/Nord2024/ChiaraC.jpg';
import proCard_Nord2024_11 from '../../images/proCards/Nord2024/ChiaraB.jpg';

import proCard_Centro2024_1 from '../../images/proCards/Centro2024/Maicol.jpg';
import proCard_Centro2024_2 from '../../images/proCards/Centro2024/GiuseppeG.jpg';
import proCard_Centro2024_3 from '../../images/proCards/Centro2024/Mattia.jpg';
import proCard_Centro2024_4 from '../../images/proCards/Centro2024/Gaetano.jpg';
import proCard_Centro2024_5 from '../../images/proCards/Centro2024/Nicholas.jpg';
import proCard_Centro2024_6 from '../../images/proCards/Centro2024/GiuseppeB.jpg';
import proCard_Centro2024_7 from '../../images/proCards/Centro2024/Daniele.jpg';
import proCard_Centro2024_8 from '../../images/proCards/Centro2024/Stefania.jpg';
import proCard_Centro2024_9 from '../../images/proCards/Centro2024/Ester.jpg';

import Carousel from 'react-multi-carousel';
import { Link } from 'react-router-dom';

const HallOfFame = () => {
  const responsive = {
    desktop: {
      breakpoint: { max: 4000, min: 894 },
      items: 3,
    },
    tablet: {
      breakpoint: { max: 894, min: 587 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 587, min: 0 },
      items: 1,
    },
  };
  return (
    <>
      <Title title='Hall of Fame' />
      <main className='hallOfFamePage'>
        <h1>Hall Of Fame</h1>
        <p>
          QUESTA PAGINA &Egrave; INTERAMENTE <b>DEDICATA ALLA MEMORIA</b> DI
          COLORO CHE SONO RIUSCITI AD AGGIUDICARSI UN POSTO TRA I{' '}
          <b>CAMPIONI</b>.
        </p>
        <p>
          QUI FIGURANO GLI ATLETI IN GARA CHE SI SFIDERANNO PER L'
          <b>ASSOLUTO ITALIA</b>.
        </p>

        <section className='proCard'>
          <h1>PRO CARD 2024</h1>
          <p>Caricate e aggiorante dopo ogni evento</p>
          <img src={proCardFronte} alt='' />
          <img src={proCardRetro} alt='' />

          <div className='nord'>
            <h1>SEZIONE NORD</h1>
            <div className='weight'>
              <h2>Lightweight</h2>
              <Carousel
                className='carousel'
                responsive={responsive}
                ssr={true} // means to render carousel on server-side.
                infinite={true}
                removeArrowOnDeviceType={['desktop']}
                autoPlaySpeed={3000}>
                <div className='card'>
                  <img src={proCard_Nord2024_1} alt='' />
                  <h2>Vincenzio Antonio Blundo</h2>
                  <Link
                    to='https://www.instagram.com/enzo_raeulerrrr/'
                    target='_blank'
                    rel='noreferrer'>
                    @enzo_raeulerrrr
                  </Link>
                </div>
                <div className='card'>
                  <img src={proCard_Nord2024_3} alt='' />
                  <h2>Alessandro Passoni</h2>
                  <Link
                    to='https://www.instagram.com/alepassoni_/'
                    target='_blank'
                    rel='noreferrer'>
                    @alepassoni_
                  </Link>
                </div>
                <div className='card'>
                  <img src={proCard_Nord2024_2} alt='' />
                  <h2>Filippo Sesana</h2>
                  <Link
                    to='https://www.instagram.com/filippo.sesana/'
                    target='_blank'
                    rel='noreferrer'>
                    @filippo.sesana
                  </Link>
                </div>
              </Carousel>
            </div>
            <div className='weight'>
              <h2>Middleweight</h2>
              <Carousel
                className='carousel'
                responsive={responsive}
                ssr={true} // means to render carousel on server-side.
                infinite={true}
                removeArrowOnDeviceType={['desktop']}
                autoPlaySpeed={3000}>
                <div className='card'>
                  <img src={proCard_Nord2024_4} alt='' />
                  <h2>Luca Tempera</h2>
                  <Link
                    to='https://www.instagram.com/lord.tempera/'
                    target='_blank'
                    rel='noreferrer'>
                    @lord.tempera
                  </Link>
                </div>
                <div className='card'>
                  <img src={proCard_Nord2024_6} alt='' />
                  <h2>Simone Alfano</h2>
                  <Link
                    to='https://www.instagram.com/simone.alfano_/'
                    target='_blank'
                    rel='noreferrer'>
                    @simone.alfano_
                  </Link>
                </div>
                <div className='card'>
                  <img src={proCard_Nord2024_5} alt='' />
                  <h2>Riccardo Pisani</h2>
                  <Link
                    to='https://www.instagram.com/ilpisuu/'
                    target='_blank'
                    rel='noreferrer'>
                    @ilpisuu
                  </Link>
                </div>
              </Carousel>
            </div>
            <div className='weight'>
              <h2>Heavyweight</h2>
              <Carousel
                className='carousel'
                responsive={responsive}
                ssr={true} // means to render carousel on server-side.
                infinite={true}
                removeArrowOnDeviceType={['desktop']}
                autoPlaySpeed={3000}>
                <div className='card'>
                  <img src={proCard_Nord2024_7} alt='' />
                  <h2>Samuele Ramazzotti</h2>
                  <Link
                    to='https://www.instagram.com/samueleramazzotti/'
                    target='_blank'
                    rel='noreferrer'>
                    @samueleramazzotti
                  </Link>
                </div>
                <div className='card'>
                  <img src={proCard_Nord2024_8} alt='' />
                  <h2>Dennis Biagi</h2>
                  <Link
                    to='https://www.instagram.com/dennis_biagi/'
                    target='_blank'
                    rel='noreferrer'>
                    @dennis_biagi
                  </Link>
                </div>
                <div className='card'>
                  <img src={proCard_Nord2024_9} alt='' />
                  <h2>Sergio Limarelli</h2>
                  <Link
                    to='https://www.instagram.com/chef_sergio_limarelli/'
                    target='_blank'
                    rel='noreferrer'>
                    @chef_sergio_limarelli
                  </Link>
                </div>
              </Carousel>
            </div>
            <div className='weight'>
              <h2>Female</h2>
              <Carousel
                className='carousel'
                responsive={responsive}
                ssr={true} // means to render carousel on server-side.
                infinite={true}
                removeArrowOnDeviceType={['desktop']}
                autoPlaySpeed={3000}>
                <div className='card'>
                  <img src={proCard_Nord2024_10} alt='' />
                  <h2>Chiara Cavalleri</h2>
                  <Link
                    to='https://www.instagram.com/chiaracavalleri/'
                    target='_blank'
                    rel='noreferrer'>
                    @chiaracavalleri
                  </Link>
                </div>
                <div className='card'>
                  <img src={proCard_Nord2024_11} alt='' />
                  <h2>Chiara Biasi</h2>
                  <Link
                    to='https://www.instagram.com/6_freeky_9/'
                    target='_blank'
                    rel='noreferrer'>
                    @6_freeky_9
                  </Link>
                </div>
              </Carousel>
            </div>
          </div>

          <div className='centro'>
            <h1>SEZIONE CENTRO</h1>
            <div className='weight'>
              <h2>Lightweight</h2>
              <Carousel
                className='carousel'
                responsive={responsive}
                ssr={true} // means to render carousel on server-side.
                infinite={true}
                removeArrowOnDeviceType={['desktop']}
                autoPlaySpeed={3000}>
                <div className='card'>
                  <img src={proCard_Centro2024_1} alt='' />
                  <h2>Maicol Beltrame</h2>
                  <Link
                    to='https://www.instagram.com/maicolbeltrame/'
                    target='_blank'
                    rel='noreferrer'>
                    @maicolbeltrame
                  </Link>
                </div>
                <div className='card'>
                  <img src={proCard_Centro2024_2} alt='' />
                  <h2>Giuseppe Guida</h2>
                  <Link
                    to='https://www.instagram.com/giuseppeguida_sw/'
                    target='_blank'
                    rel='noreferrer'>
                    @giuseppeguida_sw
                  </Link>
                </div>
                <div className='card'>
                  <img src={proCard_Centro2024_3} alt='' />
                  <h2>Mattia Tessitore</h2>
                  <Link
                    to='https://www.instagram.com/_mattiatessitore_/'
                    target='_blank'
                    rel='noreferrer'>
                    @_mattiatessitore_
                  </Link>
                </div>
              </Carousel>
            </div>
            <div className='weight'>
              <h2>Middleweight</h2>
              <Carousel
                className='carousel'
                responsive={responsive}
                ssr={true} // means to render carousel on server-side.
                infinite={true}
                removeArrowOnDeviceType={['desktop']}
                autoPlaySpeed={3000}>
                <div className='card'>
                  <img src={proCard_Centro2024_4} alt='' />
                  <h2>Gaetano Guida</h2>
                  <Link
                    to='https://www.instagram.com/grl_calisthenics/'
                    target='_blank'
                    rel='noreferrer'>
                    @grl_calisthenics
                  </Link>
                </div>
                <div className='card'>
                  <img src={proCard_Centro2024_5} alt='' />
                  <h2>Nicholas Matassa</h2>
                  <Link
                    to='https://www.instagram.com/nicholasmatassaa /'
                    target='_blank'
                    rel='noreferrer'>
                    @nicholasmatassaa
                  </Link>
                </div>
                <div className='card'>
                  <img src={proCard_Centro2024_6} alt='' />
                  <h2>Giuseppe Bruno</h2>
                  <Link
                    to='https://www.instagram.com/giuseppebruno19888/'
                    target='_blank'
                    rel='noreferrer'>
                    @giuseppebruno19888
                  </Link>
                </div>
              </Carousel>
            </div>
            <div className='weight'>
              <h2>Heavyweight</h2>
              <Carousel
                className='carousel'
                responsive={responsive}
                ssr={true} // means to render carousel on server-side.
                infinite={true}
                removeArrowOnDeviceType={['desktop']}
                autoPlaySpeed={3000}>
                <div className='card'>
                  <img src={proCard_Centro2024_7} alt='' />
                  <h2>Daniele Colautti</h2>
                  <Link
                    to='https://www.instagram.com/danielecolauttii /'
                    target='_blank'
                    rel='noreferrer'>
                    @danielecolauttii
                  </Link>
                </div>
              </Carousel>
            </div>
            <div className='weight'>
              <h2>Female</h2>
              <Carousel
                className='carousel'
                responsive={responsive}
                ssr={true} // means to render carousel on server-side.
                infinite={true}
                removeArrowOnDeviceType={['desktop']}
                autoPlaySpeed={3000}>
                <div className='card'>
                  <img src={proCard_Centro2024_8} alt='' />
                  <h2>Stefania Menni</h2>
                  <Link
                    to='https://www.instagram.com/stefiporsche/'
                    target='_blank'
                    rel='noreferrer'>
                    @stefiporsche
                  </Link>
                </div>
                <div className='card'>
                  <img src={proCard_Centro2024_9} alt='' />
                  <h2>Ester Lo Iacono</h2>
                  <Link
                    to='https://www.instagram.com/the_dis.ester/'
                    target='_blank'
                    rel='noreferrer'>
                    @the_dis.ester
                  </Link>
                </div>
              </Carousel>
            </div>
          </div>
        </section>
      </main>
    </>
  );
};

export default HallOfFame;
