import {
  CONTACT_FAIL,
  CONTACT_REQUEST,
  CONTACT_SUCCESS,
} from '../../constants/infoConstants';

export const contactReducers = (state = {}, action) => {
  switch (action.type) {
    case CONTACT_REQUEST:
      return { loading: true, ...state };

    case CONTACT_SUCCESS:
      return { loading: false, message: action.payload };

    case CONTACT_FAIL:
      return { loading: false, error: action.payload };

    default:
      return state;
  }
};
