import React from 'react';
import { useForm } from 'react-hook-form';

import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import { resetPassword } from '../../redux/actions/userActions';
import Title from '../../components/Title';

const RestorePwdForm = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const token = new URLSearchParams(location.search).get('token');
  const { loading, error, message } = useSelector(
    (state) => state.restorePassword
  );

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();

  const onSubmit = async ({ password }) => {
    reset();
    dispatch(resetPassword(password, token));
  };

  return (
    <>
      <Title title='Recupera Password' />
      {loading && <div className='spinner'></div>}
      <main className='authContainer'>
        <form onSubmit={handleSubmit(onSubmit)}>
          <section className='title'>
            <h2>Recupera password</h2>
            <small>
              Crea una nuova password e ricorda di salvarla in un luogo sicuro!
            </small>
          </section>

          {/* Errors */}
          <section>
            {!token && <h1 className='error'>Problemi con il Token</h1>}
            {error && <p className='error'>C'è stato un errore!</p>}
          </section>

          {/* Message */}
          <section>
            {message?.status === 'OK' && (
              <>
                <p className='message'>Password aggiornata con successo!</p>
                <Link className='btn' to='../login'>
                  Accedi
                </Link>
              </>
            )}
          </section>

          {/* Password */}
          {!message?.status && (
            <>
              <section className={`${errors.passoword ? 'wrong' : ''}`}>
                <input
                  type='password'
                  {...register('password', {
                    required: 'Campo obbligatorio',
                    minLength: {
                      value: 8,
                      message: 'Password troppo corta (min 8 caratteri)',
                    },
                  })}
                  id='password'
                  placeholder=' '
                  className={`${errors.password ? 'wrong' : ''}`}
                />
                <label htmlFor='password'>Password</label>
                {errors.password && (
                  <p className='error'>{errors.password.message}</p>
                )}
              </section>

              <input type='submit' value='Reset' className='btn' />
            </>
          )}
        </form>
      </main>
    </>
  );
};

export default RestorePwdForm;
