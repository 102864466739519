import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import {
  applyDiscount,
  getOrder,
  removeCoupon,
} from '../../redux/actions/storeActions';
import { useDispatch, useSelector } from 'react-redux';
import OrderItem from '../../components/store/OrderItem';
import Price from '../../components/store/Price';
import { MdOutlineArrowDropDownCircle } from 'react-icons/md';
import { AiOutlineShoppingCart as CartIcon } from 'react-icons/ai';
import TicketOrderItem from '../../components/store/TicketOrderItem';

const Summary = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();

  const [showRecap, setShowRecap] = React.useState(false);

  const dispatch = useDispatch();

  const { order, error, message } = useSelector((state) => state.order);

  useEffect(() => {
    dispatch(getOrder());
  }, [dispatch]);

  const onSubmit = ({ coupon }) => {
    dispatch(applyDiscount(coupon));
    reset();
  };

  const onClick = (e) => {
    e.stopPropagation();
    dispatch(removeCoupon());
  };

  const renderOrderItems = (products) => {
    return products.map((item) => {
      return <OrderItem key={item.product.id} item={item} />;
    });
  };

  const renderTickets = (tickets) => {
    return tickets.map((ticket) => {
      return (
        <TicketOrderItem key={ticket.id} ticket={ticket} where='checkout' />
      );
    });
  };

  return (
    <div className='checkoutSummary'>
      <div className={`showRecap ${showRecap ? 'up' : 'down'}`}>
        <CartIcon className='cart' />
        <p className='text'>Riepilogo</p>
        <Price className='total' amount={order?.details?.total} />
        <MdOutlineArrowDropDownCircle
          onClick={() => setShowRecap(!showRecap)}
          className='arrow'
        />
      </div>
      <div className={`recap ${showRecap ? 'show' : 'hide'}`}>
        {order?.cart?.products && renderOrderItems(order?.cart?.products)}
        {order?.cart?.tickets && renderTickets(order?.cart?.tickets)}
        <hr />
        {order?.details && (
          <div className='subtotale'>
            {order?.coupon && (
              <div>
                Sconto: <Price amount={order?.discount} />
              </div>
            )}
            <div>
              Subtotale: <Price amount={order?.details?.subtotal} />
            </div>
            {order.needs_shipping && (
              <>
                <div>
                  Spedizione:{' '}
                  {Number(order?.details?.shipping_cost) === 0.0 ? (
                    <p>Gratuita</p>
                  ) : (
                    <Price amount={order?.details?.shipping_cost} />
                  )}
                </div>
                {Number(order?.details?.shipping_cost) !== 0.0 && (
                  <p>
                    Con un ordine di più di 50&euro; avrai la spedizione
                    gratuita!
                  </p>
                )}
              </>
            )}
          </div>
        )}
        <hr />
        <div className='totale'>
          {order?.details && (
            <div>
              Totale: <Price amount={order?.details?.total} />
            </div>
          )}
        </div>
      </div>
      <section className='coupon'>
        <h3>Codice Sconto</h3>
        {order?.coupon && (
          <p className='coupon'>
            <button onClick={(e) => onClick(e)} className='remove'>
              x
            </button>
            Codice applicato: <span>{order.coupon}</span>
          </p>
        )}
        {!order?.coupon && (
          <>
            <form className='coupon' onSubmit={handleSubmit(onSubmit)}>
              <section>
                <input
                  type='text'
                  placeholder=' '
                  {...register('coupon', {
                    required: 'Campo obbligatorio',
                  })}
                />
                <label>Coupon</label>
              </section>

              <input type='submit' value='applica' />
            </form>
            {message && <p>{message}</p>}
            {(error || errors?.coupon) && (
              <p>{errors?.coupon?.message || error}</p>
            )}
          </>
        )}
        <hr />
      </section>
    </div>
  );
};

export default Summary;
