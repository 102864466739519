import React, { useEffect } from 'react';
import Title from '../../components/Title';

const Iubenda = ({ termsId }) => {
  useEffect(() => {
    var s = document.createElement('script');
    let tag = document.getElementsByTagName('script')[0];

    s.src = 'https://cdn.iubenda.com/iubenda.js';

    tag.parentNode.insertBefore(s, tag);
  }, []);

  return (
    <a
      href={`https://www.iubenda.com/termini-e-condizioni/${termsId}`}
      className='iubenda-noiframe iubenda-embed iubenda-noiframe iub-body-embed'
      id='iubenda-embed'
      title='Termini e Condizioni '>
      Termini e Condizioni
    </a>
  );
};

const TerminiECondizioni = () => {
  return (
    <>
      <Title title='Termini e Condizioni' />
      <main className='termsPage'>
        <div className='pageTitle'>
          <h1>Termini e Condizioni</h1>
          <p>
            Termini e Condizioni per la Vendita di Prodotti - Resistance srl.
          </p>
        </div>

        <Iubenda termsId='94674002' />
      </main>
    </>
  );
};

export default TerminiECondizioni;
