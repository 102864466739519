import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import DiscountPrice from './DiscountPrice';
import LazyLoad from 'react-lazyload';

const getImagePath = async (product) => {
  const { default: path } = await import(
    `../../images/products/${product.images_folder}/${product.model}/0.jpg`
  );
  return path;
};

const ProductCard = ({ product }) => {
  let [imagePath, setImagePath] = useState(null);

  useEffect(() => {
    getImagePath(product).then(setImagePath);
  }, [product]);

  return (
    <div className='productCard'>
      <Link to={`../store/product/${product.default}`}>
        <div className='imgContainer'>
          {/* In fase di testing */}
          <LazyLoad height={200} offset={200}>
            <img src={imagePath} alt='' />
          </LazyLoad>
        </div>
        <div className='desc'>
          <h3>{product.name}</h3>
          {product.linea && <p className='zone'>{product.linea}</p>}
          <p>{product.model}</p>
        </div>
      </Link>
      <DiscountPrice element={product} />
    </div>
  );
};

export default ProductCard;
