import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import QuantityInput from './QuantityInput';
import Price from './Price';
import { AiOutlineClose } from 'react-icons/ai';
import { useDispatch } from 'react-redux';
import {
  getOrder,
  removeProductFromCart,
} from '../../redux/actions/storeActions';

const getImagePath = async (product) => {
  const { default: path } = await import(
    `../../images/products/${product.images_folder}/${product.model}/0.jpg`
  );
  return path;
};

const CartItem = ({ item }) => {
  let { product, quantity } = item;

  const calcDiscountedPrice = () => {
    if (product?.discount?.percent) {
      return product.price - product.price * product?.discount?.percent;
    } else if (product?.discount?.amount) {
      return product.price - product?.discount?.amount;
    } else {
      return product.price;
    }
  };

  const dispatch = useDispatch();

  const onClick = (e) => {
    e.stopPropagation();
    dispatch(removeProductFromCart(product.id));
    setTimeout(() => {
      dispatch(getOrder());
    }, 1000);
  };

  let [imagePath, setImagePath] = useState(null);

  useEffect(() => {
    getImagePath(product).then(setImagePath);
  }, [product]);

  return (
    <div className='cartItem'>
      <img src={imagePath} alt='' />
      <p className='productName'>
        <Link to={`../store/product/${item.product.id}`}>{product.name}</Link>
        <small>
          {product.model} {product.size && <span> - {product.size}</span>}
        </small>
      </p>

      {product.discount === null ? (
        <Price amount={product.price} className='productPrice' />
      ) : (
        <Price amount={calcDiscountedPrice()} className='discountPrice' />
      )}
      <QuantityInput
        productId={product.id}
        quantity={quantity}
        stock={product.stock}
      />
      <Price
        amount={calcDiscountedPrice() * quantity}
        className='productTotal'
      />
      <button onClick={(e) => onClick(e, 'remove')} className='Xremove'>
        <AiOutlineClose />
      </button>
    </div>
  );
};

export default CartItem;
