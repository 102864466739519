import React from 'react';
import { useSelector } from 'react-redux';
import { NavLink, Link } from 'react-router-dom';
import { FaShoppingCart } from 'react-icons/fa';
import { BiUser } from 'react-icons/bi';

const Menu = () => {
  const { user } = useSelector((state) => state.userLogin);

  return (
    <>
      <nav>
        <NavLink to='competizioni'>Competizioni</NavLink>
        <NavLink to='regolamento'>Regolamento</NavLink>
        <NavLink to='hallOfFame'>Hall of Fame</NavLink>
        <NavLink to='best'>best</NavLink>
        <NavLink to='contatti'>Contatti</NavLink>
        <NavLink to='store'>Shop</NavLink>
        <Link to='store/cart'>
          <FaShoppingCart className='icon' />
        </Link>
        <Link to={`${user ? 'account' : 'login'}`}>
          <BiUser className='icon' />
        </Link>
      </nav>
    </>
  );
};

export default Menu;
