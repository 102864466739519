import React from 'react';
import Title from '../../components/Title';
import InsuranceImg from '../../images/insurance/insurance.jpg';

const Regolamento = () => {
  let origin = window.location.origin;
  if (origin === 'http://localhost:3000') origin = 'http://localhost:8000';
  return (
    <>
      <Title title='Regolamento' />
      <main className='regolamentoPage'>
        <section className='regolamento'>
          <h1>Regolamento</h1>
          <p>REGOLAMENTO STAGIONE AGONISTICA 2024</p>

          <a href={`${origin}/static/regolamento.pdf`} download className='btn'>
            Scarica
          </a>
        </section>
        <hr />
        <section className='assicurazione'>
          <h1>Assicurazione</h1>
          <p>
            Per tutelare l'atleta e per garantire la vostra sicurezza Resistance
            si è appoggiata ad enti di promozione sportiva certificati dal CONI.
            La copertura assicurativa avrà la durata di 1 anno a partire dal
            giorno del tesseramento. La copertura assicurativa ed il
            corrispettivo acquisto è obbligatorio per chiunque voglia
            partecipare ad un evento Resistance per la prima volta. Il costo è
            pari a €30,00 e l'acquisto verrà effettuato solamente con la
            prima iscrizione.
          </p>
          <img src={InsuranceImg} alt='' />
        </section>
      </main>
    </>
  );
};

export default Regolamento;
