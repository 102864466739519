import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { confirmPayment, getOrder } from '../../redux/actions/storeActions';
import { Link } from 'react-router-dom';
import Title from '../../components/Title';
import Price from '../../components/store/Price';
import OrderItem from '../../components/store/OrderItem';
import TicketOrderItem from '../../components/store/TicketOrderItem';

const Confirmed = () => {
  const dispatch = useDispatch();
  const { order } = useSelector((state) => state.order);

  useEffect(() => {
    dispatch(confirmPayment());
    dispatch(getOrder());
  }, [dispatch]);

  const renderOrderItems = (products) => {
    return products.map((item) => {
      return <OrderItem key={item.product.id} item={item} />;
    });
  };

  const renderTickets = (tickets) => {
    return tickets.map((ticket) => {
      return <TicketOrderItem key={ticket.id} ticket={ticket} />;
    });
  };

  return (
    <>
      <Title title='Ordine confermato' />
      <main className='confirmedPage'>
        <h1>Ordine confermato!</h1>

        {order?.details && (
          <>
            <div className='checkoutSummary'>
              <div className={`recap show`}>
                {order?.cart?.products &&
                  renderOrderItems(order?.cart?.products)}
                {order?.cart?.tickets && renderTickets(order?.cart?.tickets)}
                {order?.coupon && (
                  <>
                    <hr />
                    <p className='coupon'>
                      Sconto applicato: <span>{order.coupon}</span>
                    </p>
                  </>
                )}
                <hr />
                {order?.details && (
                  <div className='subtotale'>
                    <div>
                      Subtotale: <Price amount={order?.details?.subtotal} />
                    </div>
                    <div>
                      Spedizione:{' '}
                      {Number(order?.details?.shipping_cost) === 0.0 ? (
                        <p>Gratuita</p>
                      ) : (
                        <Price amount={order?.details?.shipping_cost} />
                      )}
                    </div>
                  </div>
                )}
                <hr />
                <div className='totale'>
                  {order?.details && (
                    <div>
                      Totale: <Price amount={order?.details?.total} />
                    </div>
                  )}
                </div>
              </div>
            </div>
          </>
        )}

        <p>Riceverai presto una email di conferma</p>

        <Link to='../store' className='btn'>
          Torna allo Shop
        </Link>
      </main>
    </>
  );
};

export default Confirmed;
