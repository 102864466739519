import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, NavLink, Outlet, Route, Routes } from 'react-router-dom';

import Protected from '../../components/Protected';
import { getUserDetails } from '../../redux/actions/userActions';
import Title from '../../components/Title';

import Profile from '../../components/account/settings/Profile';
import DeleteAccount from '../../components/account/settings/DeleteAccount';
// import Notifications from '../../components/account/ettings/Notifications';
import UpdatePassword from '../../components/account/settings/UpdatePassword';

const AccountSettings = () => {
  const dispatch = useDispatch();
  const {
    user,
    loading: loadingUser,
    error: errorUser,
  } = useSelector((state) => state.userDetails);
  const {
    loading: loadingUpdate,
    message: messageUpdate,
    error: errorUpdate,
  } = useSelector((state) => state.updateAccount);

  useEffect(() => {
    dispatch(getUserDetails());
  }, [dispatch]);

  return (
    <>
      <Title title='Impostazioni' />
      <main className='settingsPage'>
        <h1 className='appPageTitle'>Impostazioni Profilo</h1>
        <Protected />
        {loadingUser && <div className='spinner'></div>}
        {loadingUpdate && <div className='spinner'></div>}
        {errorUser && <p className='appError'>{errorUser}</p>}
        {errorUpdate && <p className='appError'>{errorUpdate}</p>}
        {messageUpdate && <p className='appMessage'>{messageUpdate.message}</p>}
        {user && (
          <div className='container'>
            <aside>
              <nav>
                <NavLink to='../account/settings/profile'>
                  Aggiorna Profilo
                </NavLink>

                <NavLink to='../account/settings/change_password'>
                  Cambia password
                </NavLink>

                {/* <NavLink
                to='/account/settings/notifications'>
                Notifications
              </NavLink> */}

                <NavLink to='../account/settings/delete_account'>
                  Elimina profilo
                </NavLink>
              </nav>
            </aside>
            <div>
              <Outlet />
            </div>
            <Routes>
              <Route
                path='profile'
                user={user}
                exact
                element={<Profile user={user.info} />}
              />

              <Route
                path='change_password'
                user={user}
                element={<UpdatePassword user={user.info} />}
                exact
              />
              {/* 
              <Route path='notifications' user={user} exact element={ <Notifications user={user.info} />}/>  */}

              <Route
                path='delete_account'
                user={user}
                exact
                element={<DeleteAccount user={user.info} />}
              />
            </Routes>
          </div>
        )}
        <Link to='../account'>Torna indietro</Link>
      </main>
    </>
  );
};

export default AccountSettings;
