import React, { useEffect } from 'react';
import Title from '../../components/Title';

const Iubenda = ({ policyId }) => {
  useEffect(() => {
    var s = document.createElement('script');
    let tag = document.getElementsByTagName('script')[0];

    s.src = 'https://cdn.iubenda.com/iubenda.js';

    tag.parentNode.insertBefore(s, tag);
  }, []);

  return (
    <a
      href={`https://www.iubenda.com/privacy-policy/${policyId}`}
      className='iubenda-noiframe iubenda-embed iubenda-noiframe iub-body-embed'
      id='iubenda-embed'
      title='Privacy Policy '>
      Privacy Policy
    </a>
  );
};

const Privacy = () => {
  return (
    <>
      <Title title='Privacy Policy' />
      <main className='privacyPage'>
        <div className='pageTitle'>
          <h1>Privacy Policy</h1>
          <p>Consulta la nostra Privacy Policy</p>
        </div>

        <Iubenda policyId='94674002' />
      </main>
    </>
  );
};

export default Privacy;
