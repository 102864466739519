export const states = [
  'Italia',
  'Albania',
  'Algeria',
  'Andorra',
  'Angola',
  'Anguilla',
  'Antigua',
  'Argentina',
  'Armenia',
  'Aruba',
  'Ascension',
  'Australia',
  'Austria',
  'Azerbaijan',
  'Bahamas',
  'Bahrain',
  'Bangladesh',
  'Barbados',
  'Barbuda',
  'Belau',
  'Belgium',
  'Belize',
  'Bermuda',
  'Bhutan',
  'Bolivia',
  'Bosnia-Hercegovina',
  'Botswana',
  'Brazil',
  'British Virgin Is.',
  'Brunei Darussalam',
  'Bulgaria',
  'Burkina Faso',
  'Burundi',
  'Cambodia',
  'Cameroon',
  'Canada',
  'Cape Verde',
  'Cayman Is.',
  'Central African Republic',
  'Chad',
  'Channel Isles',
  'Chile',
  'China',
  'Christmas Is.',
  'Cocos Is.',
  'Colombia',
  'Columbia',
  'Comoros',
  'Congo',
  'Costa Rica',
  'Croatia',
  'Curacoa',
  'Cyprus',
  'Czech Republic',
  'Denmark',
  'Djibouti',
  'Dominica',
  'Dominican Republic',
  'East Timor',
  'Ecuador',
  'Egypt',
  'El Salvador',
  'Equitorial Guinea',
  'Eritrea',
  'Estonia',
  'Ethiopia',
  'Falkland Is.',
  'Faroe Islands',
  'Fiji',
  'Finland',
  'France',
  'French Guiana',
  'French Polynesia',
  'French West Indies',
  'Gabon',
  'Gambia',
  'Gaza and Khan Yunis',
  'Georgia',
  'Germany',
  'Ghana',
  'Gibraltar',
  'Greece',
  'Greenland',
  'Grenada',
  'Guam',
  'Guatemala',
  'Guinea',
  'Guinea-Bissau',
  'Guyana',
  'Haiti',
  'Honduras',
  'Hong Kong',
  'Hungary',
  'Iceland',
  'India',
  'Indonesia',
  'Iran',
  'Iraq',
  'Ireland',
  'Irish Republic',
  'Israel',
  'Ivory Coast',
  'Jamaica',
  'Japan',
  'Jordan',
  'Kazakhstan',
  'Kenya',
  'Kirghizstan',
  'Kiribati',
  'Kuwait',
  'Laos',
  'Latvia',
  'Lebanon',
  'Lesotho',
  'Liberia',
  'Libya',
  'Liechtenstein',
  'Lithuania',
  'Luxembourg',
  'Macao',
  'Macedonia',
  'Madagascar',
  'Malawi',
  'Malaysia',
  'Maldives',
  'Mali',
  'Malta',
  'Marshall Is.',
  'Mauritania',
  'Mauritius',
  'Mexico',
  'Micronesia',
  'Moldova',
  'Monaco',
  'Mongolia',
  'Montenegro',
  'Montserrat',
  'Morocco',
  'Mozambique',
  'Myanmar',
  'Namibia',
  'Nauru Is.',
  'Nepal',
  'Netherlands',
  'Netherlands Antilles',
  'New Caledonia',
  'New Zealand',
  'Nicaragua',
  'Niger Republic',
  'Nigeria',
  'Norfolk Is.',
  'Northern Mariana Is.',
  'Norway',
  'Oman',
  'Pakistan',
  'Panama',
  'Papua New Guinea',
  'Paraguay',
  'Peru',
  'Philippines',
  'Pitcairn Is.',
  'Poland',
  'Portugal',
  'Puerto Rico',
  'Qatar',
  'Reunion',
  'Romania',
  'Rwanda',
  'S. Sandwich Is.',
  'Samoa',
  'San Marino',
  'Sao Tome',
  'Saudi Arabia',
  'Senegal',
  'Serbia',
  'Seychelles',
  'Sierra Leone',
  'Singapore',
  'Slovak Republic',
  'Slovenia',
  'Solomon Is.',
  'Somalia',
  'South Africa',
  'South Georgia',
  'South Korea',
  'Spain (except Canary Islands)',
  'Spain Canary Islands',
  'Sptitzbergen',
  'Sri Lanka',
  'St Helena',
  'St Kitts and Nevis',
  'St Lucia',
  'St Pierre',
  'St Vincent',
  'Sudan',
  'Suriname',
  'Swaziland',
  'Sweden',
  'Switzerland',
  'Syria',
  'Taiwan',
  'Tajikstan',
  'Tanzania',
  'Thailand',
  'Tibet',
  'Togo',
  'Tonga',
  'Trinidad and Tobago',
  'Tristan da Cunha',
  'Tunisia',
  'Turkey',
  'Turkmenistan',
  'Turks and Ciacos Is.',
  'Tuvalu',
  'U.A.E.',
  'Uganda',
  'UK',
  'Uruguay',
  'USA',
  'Uzbekistan',
  'Vanatu',
  'Vatican City',
  'Venezuela',
  'Vietnam',
  'Virgin Is. US',
  'Wake Is.',
  'Wallis and Funtuna Is.',
  'Western Samoa',
  'Yemen',
  'Zaire',
  'Zambia',
  'Zimbabwe',
];
