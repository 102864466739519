import {
  CREATE_TICKET_FAILED,
  CREATE_TICKET_REQUEST,
  CREATE_TICKET_SUCCESS,
  REMOVE_TICKET_FAILED,
  REMOVE_TICKET_REQUEST,
  REMOVE_TICKET_SUCCESS,
} from '../../constants/eventCostants';
import {
  ADD_PRODUCT_TO_CART_FAILED,
  ADD_PRODUCT_TO_CART_REQUEST,
  ADD_PRODUCT_TO_CART_SUCCESS,
  GET_CART_REQUEST,
  GET_CART_SUCCESS,
  GET_ORDER_REQUEST,
  GET_ORDER_FAILED,
  GET_ORDER_SUCCESS,
  GET_PRODUCT_FAILED,
  GET_PRODUCT_REQUEST,
  GET_PRODUCT_SUCCESS,
  REDUCE_PRODUCT_FROM_CART_FAILED,
  REDUCE_PRODUCT_FROM_CART_REQUEST,
  REDUCE_PRODUCT_FROM_CART_SUCCESS,
  REMOVE_PRODUCT_FROM_CART_FAILED,
  REMOVE_PRODUCT_FROM_CART_REQUEST,
  REMOVE_PRODUCT_FROM_CART_SUCCESS,
  STORE_FAILED,
  STORE_REQUEST,
  STORE_SUCCESS,
  GET_CART_FAILED,
  APPLY_DISCOUNT_SUCCESS,
  APPLY_DISCOUNT_REQUEST,
  APPLY_DISCOUNT_FAILED,
  SEND_INVOICE_REQUEST,
  SEND_INVOICE_SUCCESS,
  SEND_INVOICE_FAILED,
  REMOVE_COUPON_REQUEST,
  REMOVE_COUPON_SUCCESS,
  REMOVE_COUPON_FAILED,
  SEND_SHIPPING_REQUEST,
  SEND_EMAIL_REQUEST,
  SEND_EMAIL_SUCCESS,
  SEND_SHIPPING_SUCCESS,
  SEND_SHIPPING_FAILED,
  SEND_EMAIL_FAILED,
  GET_PAYMENT_INTENT_REQUEST,
  GET_PAYMENT_INTENT_SUCCESS,
  GET_PAYMENT_INTENT_FAILED,
  CONFIRM_PAYMENT_REQUEST,
  CONFIRM_PAYMENT_SUCCESS,
  CONFIRM_PAYMENT_FAILED,
} from '../../constants/storeCostants';

export const storeReducer = (state = { products: [] }, action) => {
  switch (action.type) {
    case STORE_REQUEST:
      return { loading: true, ...state };

    case STORE_SUCCESS:
      return { loading: false, products: action.payload };

    case STORE_FAILED:
      return { loading: false, error: action.payload };

    default:
      return state;
  }
};

export const productReducer = (state = {}, action) => {
  switch (action.type) {
    case GET_PRODUCT_REQUEST:
      return { loading: true };

    case GET_PRODUCT_SUCCESS:
      return { loading: false, product: action.payload };

    case GET_PRODUCT_FAILED:
      return { loading: false, error: action.payload };

    default:
      return state;
  }
};

export const cartReducer = (
  state = { cart: { products: [], tickets: [] } },
  action
) => {
  switch (action.type) {
    case ADD_PRODUCT_TO_CART_REQUEST:
    case REDUCE_PRODUCT_FROM_CART_REQUEST:
    case CREATE_TICKET_REQUEST:
    case REMOVE_TICKET_REQUEST:
    case GET_CART_REQUEST:
    case REMOVE_PRODUCT_FROM_CART_REQUEST:
      return { loading: true, ...state };

    case ADD_PRODUCT_TO_CART_SUCCESS:
    case REDUCE_PRODUCT_FROM_CART_SUCCESS:
    case REMOVE_PRODUCT_FROM_CART_SUCCESS:
    case GET_CART_SUCCESS:
      return {
        loading: false,
        products: action.payload?.products,
        tickets: action.payload?.tickets,
        message: 'Cart updated!',
      };

    case CREATE_TICKET_SUCCESS:
      return {
        ...state,
        loading: false,
        tickets: [...state.tickets, action.payload],
      };

    case REMOVE_TICKET_SUCCESS:
      return {
        ...state,
        loading: false,
        tickets: [
          ...state.tickets.filter(
            (ticket) => ticket.id !== action.payload.ticket_id
          ),
        ],
      };

    case ADD_PRODUCT_TO_CART_FAILED:
    case REDUCE_PRODUCT_FROM_CART_FAILED:
    case REMOVE_PRODUCT_FROM_CART_FAILED:
    case CREATE_TICKET_FAILED:
    case REMOVE_TICKET_FAILED:
    case GET_CART_FAILED:
      return { loading: false, error: action.payload };

    default:
      return state;
  }
};

export const orderReducer = (state = { order: {} }, action) => {
  switch (action.type) {
    case GET_ORDER_REQUEST:
      return { loading: true, order: {}, ...state };

    case APPLY_DISCOUNT_REQUEST:
    case REMOVE_COUPON_REQUEST:
    case SEND_EMAIL_REQUEST:
    case SEND_SHIPPING_REQUEST:
    case SEND_INVOICE_REQUEST:
    case CONFIRM_PAYMENT_REQUEST:
      return { loading: true, ...state };

    case GET_ORDER_SUCCESS:
    case SEND_EMAIL_SUCCESS:
    case SEND_SHIPPING_SUCCESS:
    case SEND_INVOICE_SUCCESS:
    case CONFIRM_PAYMENT_SUCCESS:
      return {
        loading: false,
        order: action.payload,
      };

    case APPLY_DISCOUNT_SUCCESS:
      return {
        loading: false,
        order: action.payload,
        message: 'Sconto applicato!',
      };

    case REMOVE_COUPON_SUCCESS:
      return {
        loading: false,
        order: action.payload,
        message: 'Coupon eliminato!',
      };

    case GET_ORDER_FAILED:
    case CONFIRM_PAYMENT_FAILED:
    case SEND_EMAIL_FAILED:
    case SEND_INVOICE_FAILED:
    case SEND_SHIPPING_FAILED:
      return { loading: false, error: action.payload };

    case APPLY_DISCOUNT_FAILED:
    case REMOVE_COUPON_FAILED:
      return { loading: false, error: action.payload, order: state.order };

    default:
      return state;
  }
};

export const paymentIntentReducer = (state = {}, action) => {
  switch (action.type) {
    case GET_PAYMENT_INTENT_REQUEST:
      return { loading: true };

    case GET_PAYMENT_INTENT_SUCCESS:
      return { loading: false, clientSecret: action.payload };

    case GET_PAYMENT_INTENT_FAILED:
      return { loading: false, error: action.payload };

    default:
      return state;
  }
};
