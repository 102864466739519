import React from 'react';
import Carousel from 'react-multi-carousel';

// Partners
import ASI_C from '../../images/partners/asi_colore.png';
import CALI_MONZA from '../../images/partners/cali_monza.png';
import CONI from '../../images/partners/coni.png';
import NOGRAVITY from '../../images/partners/gravity.png';
import IRONFIT from '../../images/partners/ironfit.png';
import SLI from '../../images/partners/SLI.png';
import UPGRADE from '../../images/partners/upgrade.png';
import FUCK_YOU from '../../images/partners/fuck_you.png';
import UNIVERSE from '../../images/partners/universe.png';
import MOVIMENTO from '../../images/partners/movimento.png';
import STREET from '../../images/partners/street_gym.png';

const responsive = {
  superLargeDesktop: {
    breakpoint: { max: 4000, min: 2000 },
    items: 10,
  },
  desktop2: {
    breakpoint: { max: 2000, min: 1500 },
    items: 8,
  },
  desktop: {
    breakpoint: { max: 1500, min: 1216 },
    items: 8,
  },
  tablet: {
    breakpoint: { max: 1216, min: 894 },
    items: 5,
  },
  mobile2: {
    breakpoint: { max: 894, min: 587 },
    items: 3,
  },
  mobile: {
    breakpoint: { max: 587, min: 0 },
    items: 3,
  },
};

const Partners = () => {
  return (
    <section>
      <Carousel
        className='partners'
        responsive={responsive}
        ssr={true} // means to render carousel on server-side.
        infinite={true}
        autoPlay={true}
        autoPlaySpeed={3000}
        arrows={false}>
        <img src={CONI} alt='CONI' />
        <img src={ASI_C} alt='ASI' className='asi' />
        <img src={FUCK_YOU} className='fuck' alt='FUCK YOU GRAVITY' />
        <img src={SLI} alt='SLI' />
        <img src={CALI_MONZA} alt='CALI MONZA' />
        <img src={NOGRAVITY} alt='NO GRAVITY' />
        <img src={IRONFIT} alt='IRONFIT' />
        <img src={UNIVERSE} alt='Universe' />
        <img src={UPGRADE} alt='UPGRADE' />
        <img src={MOVIMENTO} alt='MOVIMENTO' />
        <img src={STREET} alt='STREET GYM' />
      </Carousel>
    </section>
  );
};

export default Partners;
