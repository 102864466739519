import React from 'react';
import Title from '../../components/Title';
import { Link } from 'react-router-dom';

const Best = () => {
  return (
    <>
      <Title title='Best' />
      <main className='bestPage'>
        <section className='head'>
          <h1>Best</h1>
          <p>
            IN QUESTA PAGINA SONO CONSULTABILI TUTTI I <b>RECORD</b> ED I{' '}
            <b>TITOLI</b> CONSEGUITI DAGLI ATLETI CHE COMPETONO NELLE GARE
            RESISTANCE.
          </p>
          <p>
            QUESTO RIMARR&Agrave; UNO STORICO DOVE SAR&Agrave; POSSIBILE
            VISIONARE GLI <b>ATLETI</b> E LE LORO <b>PERFORMANCE</b>.
          </p>
          <p>
            Ogni record è stato eseguito all'interno del circuito di resistenza
            "repz-4"
            <br />
            In ordine: Muscle up - 1'rec - Dip - 1'rec -Pull up - 1'rec - Push
            up - 1'rec
            <br /> Coefficenti: Mu x2.5, Dip x1, Pull x1.5, Push x1
          </p>
        </section>
        <section className='tabella'>
          <h2>RECORD "BEST REPS" ENDURANCE NORD ITALIA 2024</h2>
          <div className='scrollable-table'>
            <table>
              <thead>
                <tr>
                  <th>Record Maschili</th>
                  <th>Atleta</th>
                  <th>Classe</th>
                  <th>Peso</th>
                  <th>Categoria</th>
                  <th>Punteggio</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>best total</td>
                  <td>dennis biagi</td>
                  <td>{'>'} 80kg</td>
                  <td>80.50kg</td>
                  <td>junior</td>
                  <td>177</td>
                </tr>
                <tr>
                  <td>best lightweight</td>
                  <td>vincenzo blundo</td>
                  <td>{'<'} 70kg</td>
                  <td>60.95kg</td>
                  <td>open</td>
                  <td>162</td>
                </tr>
                <tr>
                  <td>best middleweight</td>
                  <td>luca tempera</td>
                  <td>{'<'} 80kg</td>
                  <td>72.30kg</td>
                  <td>open</td>
                  <td>133.5</td>
                </tr>
                <tr>
                  <td>best heavyweight</td>
                  <td>dennis biagi</td>
                  <td>{'>'} 80kg</td>
                  <td>80.50kg</td>
                  <td>junior</td>
                  <td>177</td>
                </tr>
                <tr>
                  <td>best muscle up</td>
                  <td>simone alfano</td>
                  <td>{'<'} 80kg</td>
                  <td>72.30kg</td>
                  <td>junior</td>
                  <td>42.5</td>
                </tr>
                <tr>
                  <td>best dip</td>
                  <td>vincenzo blundo</td>
                  <td>{'<'} 70kg</td>
                  <td>60.95kg</td>
                  <td>open</td>
                  <td>55</td>
                </tr>
                <tr>
                  <td>best pull up</td>
                  <td>simone alfano</td>
                  <td>{'<'} 80kg</td>
                  <td>72.30kg</td>
                  <td>junior</td>
                  <td>31.5</td>
                </tr>
                <tr>
                  <td>best push up</td>
                  <td>dennis biagi</td>
                  <td>{'>'} 80kg</td>
                  <td>80.50kg</td>
                  <td>junior</td>
                  <td>89</td>
                </tr>
                <tr>
                  <td>best sub junior</td>
                  <td>alessandro passoni</td>
                  <td>{'<'} 70kg</td>
                  <td>64.70kg</td>
                  <td>sub junior</td>
                  <td>140</td>
                </tr>
                <tr>
                  <td>best junior</td>
                  <td>dennis biagi</td>
                  <td>{'>'} 80kg</td>
                  <td>80.50kg</td>
                  <td>junior</td>
                  <td>177</td>
                </tr>
              </tbody>
            </table>
          </div>
        </section>
        <section className='tabella'>
          <h2>RECORD "BEST REPS" ENDURANCE CENTRO ITALIA 2024</h2>
          <div className='scrollable-table'>
            <table>
              <thead>
                <tr>
                  <th>Record Maschili</th>
                  <th>Atleta</th>
                  <th>Classe</th>
                  <th>Peso</th>
                  <th>Categoria</th>
                  <th>Punteggio</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>best total</td>
                  <td>Nicholas Matassa</td>
                  <td>{'<'} 80kg</td>
                  <td>79.05kg</td>
                  <td>junior</td>
                  <td>138.5</td>
                </tr>
                <tr>
                  <td>best lightweight</td>
                  <td>Giuseppe Guida</td>
                  <td>{'<'} 70kg</td>
                  <td>63.60kg</td>
                  <td>open</td>
                  <td>134</td>
                </tr>
                <tr>
                  <td>best middleweight</td>
                  <td>Nicholas Matassa</td>
                  <td>{'<'} 80kg</td>
                  <td>79.05kg</td>
                  <td>junior</td>
                  <td>138.5</td>
                </tr>
                <tr>
                  <td>best heavyweight</td>
                  <td>Daniele Colautti</td>
                  <td>{'>'} 80kg</td>
                  <td>83.30kg</td>
                  <td>open</td>
                  <td>114.5</td>
                </tr>
                <tr>
                  <td>best muscle up</td>
                  <td>Giuseppe Guida</td>
                  <td>{'<'} 70kg</td>
                  <td>63.60kg</td>
                  <td>open</td>
                  <td>52.5</td>
                </tr>
                <tr>
                  <td>best dip</td>
                  <td>Nicholas Matassa</td>
                  <td>{'<'} 80kg</td>
                  <td>79.05kg</td>
                  <td>junior</td>
                  <td>43</td>
                </tr>
                <tr>
                  <td>best pull up</td>
                  <td>Maicol Beltrame</td>
                  <td>{'<'} 70kg</td>
                  <td>68.85kg</td>
                  <td>open</td>
                  <td>31.5</td>
                </tr>
                <tr>
                  <td>best push up</td>
                  <td>Nicholas Matassa</td>
                  <td>{'<'} 80kg</td>
                  <td>79.05kg</td>
                  <td>junior</td>
                  <td>48</td>
                </tr>
                <tr>
                  <td>best junior</td>
                  <td>Nicholas Matassa</td>
                  <td>{'<'} 80kg</td>
                  <td>79.05kg</td>
                  <td>junior</td>
                  <td>138.5</td>
                </tr>
              </tbody>
              <thead>
                <tr>
                  <th>Record Femminili</th>
                  <th>Atleta</th>
                  <th>Classe</th>
                  <th>Peso</th>
                  <th>Categoria</th>
                  <th>Punteggio</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>best total</td>
                  <td>Stefania Menni</td>
                  <td>Open</td>
                  <td>59.19kg</td>
                  <td>Open</td>
                  <td>74.5</td>
                </tr>
                <tr>
                  <td>best muscle up</td>
                  <td>Stefania Menni</td>
                  <td>Open</td>
                  <td>59.19kg</td>
                  <td>Open</td>
                  <td>5</td>
                </tr>
                <tr>
                  <td>best dip</td>
                  <td>Alessia Conetta</td>
                  <td>Open</td>
                  <td>54.05</td>
                  <td>open</td>
                  <td>38</td>
                </tr>
                <tr>
                  <td>best pull up</td>
                  <td>Stefania Menni</td>
                  <td>Open</td>
                  <td>59.19kg</td>
                  <td>Open</td>
                  <td>19.5</td>
                </tr>
                <tr>
                  <td>best push up</td>
                  <td>Stefania Menni</td>
                  <td>Open</td>
                  <td>59.19kg</td>
                  <td>Open</td>
                  <td>25</td>
                </tr>
                <tr>
                  <td>best master II</td>
                  <td>Clorinda Pace</td>
                  <td>Open</td>
                  <td>59.80kg</td>
                  <td>Master II</td>
                  <td>50.5</td>
                </tr>
              </tbody>
            </table>
          </div>
        </section>
        <div className='links'>
          <a href={`${origin}/static/podi.pdf`} download className='btn'>
            Scarica Podi Campionato 2024
          </a>
          <Link className='btn' to='../competizioni#premi'>
            Vai ai premi
          </Link>
        </div>
      </main>
    </>
  );
};

export default Best;
