import React from 'react';
import {
  addProductToCart,
  getOrder,
  reduceProductFromCart,
  removeProductFromCart,
} from '../../redux/actions/storeActions';
import { useDispatch } from 'react-redux';

const QuantityInput = ({ productId, quantity, stock }) => {
  const dispatch = useDispatch();
  const onClick = (e, type) => {
    e.stopPropagation();
    switch (type) {
      case 'add':
        if (quantity >= stock) return;
        dispatch(addProductToCart(productId));
        break;

      case 'reduce':
        dispatch(reduceProductFromCart(productId));
        break;

      case 'remove':
        dispatch(removeProductFromCart(productId));
        setTimeout(() => {
          dispatch(getOrder());
        }, 1000);
        break;

      default:
        return;
    }
  };

  return (
    <div className='quantityInput'>
      <button onClick={(e) => onClick(e, 'reduce')} className='sign'>
        -
      </button>
      <p>{quantity}</p>
      <button
        onClick={(e) => onClick(e, 'add')}
        className='sign'
        disabled={quantity >= stock}>
        +
      </button>
      <button onClick={(e) => onClick(e, 'remove')} className='remove'>
        Rimuovi
      </button>
    </div>
  );
};

export default QuantityInput;
