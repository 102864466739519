import React, { useEffect } from 'react';
import ProductCard from '../../components/store/ProductCard';
import { useDispatch, useSelector } from 'react-redux';
import { getStore } from '../../redux/actions/storeActions';
import Carousel from 'react-multi-carousel';

const ProductsCarousel = ({ zone }) => {
  const dispatch = useDispatch();
  const { products, loading, error } = useSelector((state) => state.store);

  useEffect(() => {
    dispatch(getStore());
  }, [dispatch]);

  const renderProducts = (products) => {
    return products
      .filter((product) => {
        return zone ? product?.linea?.toLowerCase().includes(zone) : true;
      })
      .map((product) => {
        return <ProductCard key={product.id} product={product} />;
      });
  };

  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 2000 },
      items: 6,
    },
    desktop2: {
      breakpoint: { max: 2000, min: 1500 },
      items: 5,
    },
    desktop: {
      breakpoint: { max: 1500, min: 1216 },
      items: 4,
    },
    tablet: {
      breakpoint: { max: 1216, min: 894 },
      items: 3,
    },
    mobile2: {
      breakpoint: { max: 894, min: 587 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 587, min: 0 },
      items: 1,
    },
  };

  return (
    <section className='productsCarousel'>
      <div className='products'>
        {error && <h2 className='appError'>{error}</h2>}
        {loading && <div className='spinner'></div>}
        <Carousel
          className='carousel'
          responsive={responsive}
          ssr={true} // means to render carousel on server-side.
          infinite={true}
          // autoPlay={true}
          autoPlaySpeed={3000}>
          {products && renderProducts(products)}
        </Carousel>
      </div>
    </section>
  );
};

export default ProductsCarousel;
