import React from 'react';
import { NavLink } from 'react-router-dom';
import LogoImg from '../images/logo1000.png';

const Logo = () => {
  return (
    <h1 className='logo'>
      <NavLink to='/'>
        <img src={LogoImg} alt='' />
      </NavLink>
    </h1>
  );
};

export default Logo;
