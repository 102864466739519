import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { italianProvinces } from '../../constants/provinces';
import { states } from '../../constants/states';
import { useDispatch, useSelector } from 'react-redux';
import { getOrder, sendInvoice } from '../../redux/actions/storeActions';
import { Navigate } from 'react-router-dom';

const InvoiceForm = () => {
  const {
    register,
    handleSubmit,
    watch,
    reset,
    formState: { errors },
  } = useForm();

  const dispatch = useDispatch();
  const { order, error } = useSelector((state) => state.order);

  let [useAddress, setUseAddress] = useState(false);
  let [formSent, setFormSent] = useState(false);

  useEffect(() => {
    dispatch(getOrder());
  }, [dispatch]);

  useEffect(() => {
    reset({
      state: order?.invoice_address?.state || 'Italia',
      name: order?.invoice_address?.first_name,
      lastName: order?.invoice_address?.last_name,
      address: order?.invoice_address?.address,
      addressInfo: order?.invoice_address?.info,
      city: order?.invoice_address?.city,
      CAP: order?.invoice_address?.CAP,
      provincia: order?.invoice_address?.provincia,
    });
  }, [order?.invoice_address, reset]);

  const onSubmit = (formData) => {
    dispatch(sendInvoice(formData));
    setFormSent(true);
  };

  const onClick = async () => {
    if (!useAddress) {
      reset({
        email: order?.email,
        state: order?.shipping_address?.state || 'Italia',
        name: order?.shipping_address?.first_name,
        lastName: order?.shipping_address?.last_name,
        address: order?.shipping_address?.address,
        addressInfo: order?.shipping_address?.info,
        city: order?.shipping_address?.city,
        CAP: order?.shipping_address?.CAP,
        provincia: order?.shipping_address?.provincia,
      });
    }
    setUseAddress(!useAddress);
  };

  return (
    <>
      {formSent && order?.invoice_address && <Navigate to={'../payment'} />}

      <h2>Indirizzo di Fatturazione</h2>
      <p> Necessario se stai acquistando un biglietto </p>
      <form onSubmit={handleSubmit(onSubmit)}>
        {error && <p className='error'>{error}</p>}
        {order.needs_shipping && (
          <section className={`checkbox`}>
            <input
              type='checkbox'
              {...register('useAddress')}
              onClick={(e) => onClick(e)}
              id='useAddress'
            />
            <label htmlFor='useAddress' className='checkboxLabel'>
              Utilizza Indirizzo di Spedizione
            </label>
          </section>
        )}

        <section className={`${errors.state ? 'wrong' : ''}`}>
          <select
            {...register('state')}
            defaultValue={'Italia'}
            disabled={useAddress}>
            {states.map((state, index) => (
              <option key={index} value={state}>
                {state}
              </option>
            ))}
          </select>
          <label>Stato</label>
        </section>

        <section className={`${errors.name ? 'wrong' : ''}`}>
          <input
            type='text'
            {...register('name', {
              required: 'Campo obbligatorio',
              maxLength: {
                value: 30,
                message: 'Nome troppo lungo',
              },
            })}
            disabled={useAddress}
          />
          <label>Nome</label>
          {errors.name && <p className='error'>{errors.name.message}</p>}
        </section>

        <section className={`${errors.lastName ? 'wrong' : ''}`}>
          <input
            type='text'
            {...register('lastName', {
              required: 'Campo obbligatorio',
              maxLength: {
                value: 30,
                message: 'Cognome troppo lungo',
              },
            })}
            disabled={useAddress}
          />
          <label>Cognome</label>
          {errors.lastName && (
            <p className='error'>{errors.lastName.message}</p>
          )}
        </section>

        <section className={`${errors.address ? 'wrong' : ''}`}>
          <input
            type='text'
            {...register('address', {
              required: 'Campo obbligatorio',
              maxLength: {
                value: 50,
                message: 'Cognome troppo lungo',
              },
            })}
            disabled={useAddress}
          />
          <label>Indirizzo</label>
          {errors.address && <p className='error'>{errors.address.message}</p>}
        </section>

        <section className={`${errors.addressInfo ? 'wrong' : ''}`}>
          <input
            type='text'
            placeholder='Falcoltativo'
            {...register('addressInfo')}
            disabled={useAddress}
          />
          {errors.addressInfo && (
            <p className='error'>{errors.addressInfo.message}</p>
          )}
          <label>Informazioni aggiuntive</label>
        </section>

        <section
          className={`${errors.city ? 'wrong' : ''} ${
            watch('state') === 'Italia' || watch('state') === undefined
              ? 'one-third left'
              : 'half left'
          }`}>
          <input
            type='text'
            {...register('city', {
              required: 'Campo obbligatorio',
              maxLength: {
                value: 40,
                message: 'Nome città troppo lungo',
              },
            })}
            disabled={useAddress}
          />
          <label>Città</label>
          {errors.city && <p className='error'>{errors.city.message}</p>}
        </section>

        <section
          className={`${errors.CAP ? 'wrong' : ''} ${
            watch('state') === 'Italia' || watch('state') === undefined
              ? 'one-third center'
              : 'half right'
          }`}>
          <input
            type='text'
            {...register('CAP', {
              required: 'Campo obbligatorio',
              pattern: {
                value: /^[0-9]{5}$/i,
                message: 'Codice non valido',
              }, // Example pattern for a 5-digit CAP
            })}
            disabled={useAddress}
          />
          <label>CAP</label>
          {errors.CAP && <p className='error'>{errors.CAP.message}</p>}
        </section>

        {(watch('state') === 'Italia' || watch('state') === undefined) && (
          <section className='one-third right'>
            <select {...register('provincia')} disabled={useAddress}>
              {italianProvinces.map((province, index) => (
                <option key={index} value={province.name}>
                  {`${province.name} (${province.abbreviation})`}
                </option>
              ))}
            </select>
            <label>Provincia</label>
          </section>
        )}

        <input type='submit' className='btn' value={'Procedi'} />
      </form>
    </>
  );
};

export default InvoiceForm;
