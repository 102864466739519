import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Price from './Price';
import 'react-lazy-load-image-component/src/effects/blur.css';
import { useSelector } from 'react-redux';
import DiscountPrice from './DiscountPrice';

const getImagePath = async (event, isMobile) => {
  if (isMobile) {
    const { default: path } = await import(
      `../../images/tickets/miniatura_${event.tappa}.jpg`
    );
    return path;
  } else {
    const { default: path } = await import(
      `../../images/tickets/miniatura_${event.tappa}_Large.jpg`
    );
    return path;
  }
};

const EventCard = ({ event }) => {
  let [imagePath, setImagePath] = useState(null);
  const [isMobile, setIsMobile] = useState(false);
  let { user } = useSelector((state) => state.userLogin);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    getImagePath(event, isMobile).then(setImagePath);
  }, [event, isMobile]);

  return (
    <div className='eventCard'>
      {event.active ? (
        <Link
          className='active'
          to={
            user
              ? `../store/ticket/${event.id}`
              : `../login?next=/store/ticket/${event.id}`
          }>
          <div className='imgContainer'>
            <img src={imagePath} alt='' />
          </div>
          <div className='desc'>
            <h3>{event.name}</h3>
            <p>{event.description}</p>
          </div>
        </Link>
      ) : (
        <div className='not-active'>
          <div className='imgContainer'>
            <img src={imagePath} alt='' />
          </div>
          <div className='desc'>
            <h3>{event.name}</h3>
            <p>{event.description}</p>
          </div>
        </div>
      )}

      <DiscountPrice element={event} />
      {/* <Price className='price' amount={event.price} /> */}
    </div>
  );
};

export default EventCard;
