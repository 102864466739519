import React from 'react';
import { AiFillThunderbolt, AiOutlineInstagram } from 'react-icons/ai';
import { FaWhatsapp } from 'react-icons/fa';
import { PiPhoneLight } from 'react-icons/pi';
import { AiOutlineMail } from 'react-icons/ai';
import { BiCookie } from 'react-icons/bi';
import { MdOutlinePrivacyTip } from 'react-icons/md';
import { LiaFileContractSolid } from 'react-icons/lia';

import { Link } from 'react-router-dom';

const Footer = () => {
  // Get current year
  const year = new Date().getFullYear();

  return (
    <footer>
      <section className='contact'>
        <h3>Contatti</h3>
        <p>
          <AiOutlineMail />
          <a href='mailto:info.resistancesrl@gmail.com'>
            info.resistancesrl@gmail.com
          </a>
        </p>
        <p>
          <Link
            to='https://www.instagram.com/resistance.italia/'
            target='_blank'
            rel='noreferrer'>
            <AiOutlineInstagram /> resistance.italia
          </Link>
        </p>
        <p>
          <PiPhoneLight />
          Tel: +39 320 265 4382
        </p>
        <p>
          <FaWhatsapp />
          Whatsapp: +39 320 265 4382
        </p>
      </section>
      <section className='info'>
        <h3>Informazioni</h3>
        <p>Resistance srl.</p>
        <p>Partita IVA: 13008200969</p>
        <p>REA: MB - 2698354</p>
        <p>Sede legale: Piazza Bellaria, 2 - 20814 Varedo (MB)</p>
      </section>
      <section className='docs'>
        <h3>Documenti</h3>
        <p>
          <MdOutlinePrivacyTip />
          <Link to='/docs/privacy-policy'>Privacy Policy</Link>
        </p>
        <p>
          <BiCookie />
          <Link to='/docs/cookie'>Cookie Policy</Link>
        </p>
        <p>
          <LiaFileContractSolid />
          <Link to='/docs/terms'>Termini e Condizioni</Link>
        </p>
      </section>

      <section className='website'>
        <h3>Sito web</h3>
        <p>
          <Link to='https://www.resistanceitalia.com'>
            www.resistanceitalia.com
          </Link>
        </p>
        <p>&copy; {year} Resistance srl.</p>
        <p>
          Fatto con il <AiFillThunderbolt /> da Niccolò Frassetto
        </p>
      </section>
    </footer>
  );
};

export default Footer;
