import React from 'react';

const Price = ({ amount, className }) => {
  return (
    <p className={className}>
      {Number(amount).toFixed(2)}
      <span className='euro'>&euro;</span>
    </p>
  );
};

export default Price;
