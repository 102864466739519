import React from 'react';

import postNord from '../../images/events/Nord/base.jpg';
import postCentro from '../../images/events/Centro/base.jpg';
import postSud from '../../images/events/Sud/base.jpg';
import assolutoMobile from '../../images/events/Assoluto/base.jpg';
import assolutoDesktop from '../../images/events/Assoluto/desktop.jpg';
import { Link } from 'react-router-dom';

const Events = ({ isMobile }) => {
  return (
    <>
      <section className='events'>
        <h2>Campionato Italiano</h2>
        <div>
          <Link to='../competizioni#nord'>
            <img src={postNord} alt='' />
          </Link>
          <Link to='../competizioni#centro'>
            <img src={postCentro} alt='' />
          </Link>
          <Link to='../competizioni#sud'>
            <img src={postSud} alt='' />
          </Link>
        </div>
      </section>
      <section className='assoluto'>
        <Link to='../competizioni#assoluto'>
          <img src={isMobile ? assolutoMobile : assolutoDesktop} alt='' />
        </Link>
      </section>
    </>
  );
};

export default Events;
