import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { getEvents } from '../../redux/actions/eventActions';
import Title from '../../components/Title';

const Ticket = () => {
  let [eventId, setEventId] = useState(0);
  const { events } = useSelector((state) => state.events);
  const { user } = useSelector((state) => state.userLogin);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getEvents());
  }, [dispatch]);

  return (
    <main className='ticketPage'>
      <Title title='Tappa' />
      <h2>Acquista il tuo biglietto</h2>
      <form>
        <section>
          <select
            onChange={(e) => {
              setEventId(e.target.value);
            }}
            defaultValue={'placeholder'}>
            <option value='placeholder' disabled>
              Seleziona Tappa
            </option>
            {events?.map((event) => (
              <option key={event.id} value={event.id}>
                {event.name}
              </option>
            ))}
          </select>
          <label>Tappa</label>
        </section>
      </form>
      {!user && (
        <p>
          Per procedere dovrai accedere con il tuo <b>account</b> o crearne uno.
          <br /> In questo modo potremo tenere traccia del tuo acquisto e della
          tua <b>assicurazione</b>!
        </p>
      )}
      {events?.find((event) => event.id === Number(eventId))?.active ? (
        <Link className='btn' to={eventId}>
          Procedi
        </Link>
      ) : (
        <p>Evento non disponibile</p>
      )}
    </main>
  );
};

export default Ticket;
