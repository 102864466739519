import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { italianProvinces } from '../../constants/provinces';
import { states } from '../../constants/states';
import { getOrder, sendShipping } from '../../redux/actions/storeActions';
import { useDispatch, useSelector } from 'react-redux';
import { Link, Navigate } from 'react-router-dom';

const ShippingForm = () => {
  const { order, error, loading } = useSelector((state) => state.order);
  const { user } = useSelector((state) => state.userLogin);
  let [formSent, setFormSent] = useState(false);
  let [useLegend, setUseLegend] = useState(order?.coupon === 'LEGEND 1.0');

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
    reset,
  } = useForm();

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getOrder());
  }, [dispatch]);

  useEffect(() => {
    reset({
      useLegend: useLegend,
      state: order?.shipping_address?.state || 'Italia',
      name: order?.shipping_address?.first_name,
      lastName: order?.shipping_address?.last_name,
      address: order?.shipping_address?.address,
      addressInfo: order?.shipping_address?.info,
      city: order?.shipping_address?.city,
      CAP: order?.shipping_address?.CAP,
      provincia: order?.shipping_address?.provincia,
      invoice: order?.details?.invoice || false,
    });
  }, [order, reset]);

  const onSubmit = (formData) => {
    dispatch(sendShipping(formData));
    setFormSent(true);
  };

  const onClick = async () => {
    if (!useLegend) {
      reset({
        state: 'Italia',
        address: 'Lungomare Sergio Piermanni 27',
        city: 'Civitanova Marche',
        addressInfo: '',
        CAP: '62012',
        provincia: 'Macerata',
      });
    }
    setUseLegend(!useLegend);
  };

  const isCartOnlyThunderUp = () => {
    if (order?.cart?.products && order.cart.products.length > 0) {
      console.log(order.cart.products);
      return order.cart.products.every(
        (item) => item.product.name === 'Thunder Up'
      );
    }
    return false;
  };

  return (
    <>
      {error && <p>{error}</p>}
      {formSent &&
        order?.shipping_address &&
        (order?.needs_invoice || watch('invoice') ? (
          <Navigate to={'../invoice'} />
        ) : (
          <Navigate to={'../payment'} />
        ))}
      {!user && !loading && !order?.email && <Navigate to={'../'} />}
      <form onSubmit={handleSubmit(onSubmit)}>
        <h2>Indirizzo di Spedizione</h2>

        <section className={`${errors.name ? 'wrong' : ''}`}>
          <input
            type='text'
            {...register('name', {
              required: 'Campo obbligatorio',
              maxLength: {
                value: 30,
                message: 'Nome troppo lungo',
              },
            })}
            placeholder=' '
          />
          <label>Nome</label>
          {errors.name && <p className='error'>{errors.name.message}</p>}
        </section>

        <section className={`${errors.lastName ? 'wrong' : ''}`}>
          <input
            type='text'
            {...register('lastName', {
              required: 'Campo obbligatorio',
              maxLength: {
                value: 30,
                message: 'Cognome troppo lungo',
              },
            })}
            placeholder=' '
          />
          <label>Cognome</label>
          {errors.lastName && (
            <p className='error'>{errors.lastName.message}</p>
          )}
        </section>

        {isCartOnlyThunderUp() && (
          <section className={`checkbox legendCheckbox`}>
            <input
              type='checkbox'
              {...register('useLegend')}
              onClick={(e) => onClick(e)}
              id='useLegend'
            />
            <label htmlFor='useLegend' className='checkboxLabel'>
              RESISTANCE LEGEND 1.0
              <br />{' '}
              <small>
                <Link
                  to='https://www.instagram.com/stories/resistance.italia/3397612295247249990/'
                  target='_blank'
                  rel='noreferrer'>
                  Scopri di più
                </Link>
              </small>
            </label>
          </section>
        )}

        <section className={`${errors.state ? 'wrong' : ''}`}>
          <select
            {...register('state', { required: 'Campo obbligatorio' })}
            disabled={useLegend}>
            {states.map((state, index) => (
              <option key={index} value={state}>
                {state}
              </option>
            ))}
          </select>
          <label>Stato</label>
        </section>

        <section className={`${errors.address ? 'wrong' : ''}`}>
          <input
            type='text'
            {...register('address', {
              required: 'Campo obbligatorio',
              maxLength: {
                value: 50,
                message: 'Cognome troppo lungo',
              },
            })}
            placeholder=' '
            disabled={useLegend}
          />
          <label>Indirizzo</label>
          {errors.address && <p className='error'>{errors.address.message}</p>}
        </section>

        <section className={`${errors.addressInfo ? 'wrong' : ''}`}>
          <input
            type='text'
            placeholder='Facoltativo'
            {...register('addressInfo')}
            disabled={useLegend}
          />
          <label>Informazioni aggiuntive</label>
          {errors.addressInfo && (
            <p className='error'>{errors.addressInfo.message}</p>
          )}
        </section>

        <section
          className={`${errors.city ? 'wrong' : ''} ${
            watch('state') === 'Italia' || watch('state') === undefined
              ? 'one-third left'
              : 'half left'
          }`}>
          <input
            type='text'
            {...register('city', {
              required: 'Campo obbligatorio',
              maxLength: {
                value: 40,
                message: 'Nome città troppo lungo',
              },
            })}
            placeholder=' '
            disabled={useLegend}
          />
          <label>Città</label>
          {errors.city && <p className='error'>{errors.city.message}</p>}
        </section>

        <section
          className={`${errors.CAP ? 'wrong' : ''} ${
            watch('state') === 'Italia' || watch('state') === undefined
              ? 'one-third center'
              : 'half right'
          }`}>
          <input
            type='text'
            {...register('CAP', {
              required: 'Campo obbligatorio',
              pattern: { value: /^[0-9]{5}$/i, message: 'Codice non valido' }, // Example pattern for a 5-digit CAP
            })}
            placeholder=' '
            disabled={useLegend}
          />
          <label>CAP</label>
          {errors.CAP && <p className='error'>{errors.CAP.message}</p>}
        </section>

        {(watch('state') === 'Italia' || watch('state') === undefined) && (
          <section className='one-third right'>
            <select {...register('provincia')} disabled={useLegend}>
              {italianProvinces.map((province, index) => (
                <option key={index} value={province.name}>
                  {`${province.name} (${province.abbreviation})`}
                </option>
              ))}
            </select>
            <label>Provincia</label>
          </section>
        )}

        <section className='checkbox'>
          <input type='checkbox' {...register('invoice')} id='invoice' />
          <label className='checkboxLabel' htmlFor='invoice'>
            Voglio la fattura
          </label>
        </section>

        <input type='submit' value='Procedi' className='btn' />
      </form>
    </>
  );
};

export default ShippingForm;
