import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';

import { login, signUp } from '../../../redux/actions/userActions';
import { emailRegExp, phoneRegExp } from '../../../constants/regExp';
import { Link } from 'react-router-dom';
import { trimValue } from '../../store/utils';

const SignUpForm = ({ send: setFormSent }) => {
  const dispatch = useDispatch();
  const { error, data, loading } = useSelector((state) => state.userSignUp);
  const { loading: loadingLogin } = useSelector((state) => state.userLogin);

  let [signedUp, setSignedUp] = useState(false);
  const {
    register,
    handleSubmit,
    getValues,
    formState: { errors },
  } = useForm();

  useEffect(() => {
    // Login After Sign up
    if (data?.message === 'Profilo creato!') {
      dispatch(login(getValues('email'), getValues('password')));
    }
  }, [data, getValues, dispatch]);

  const onSubmit = async ({ ...formData }) => {
    if (!signedUp || !loadingLogin) {
      dispatch(signUp(formData));
      setSignedUp(true);
    }
    if (setFormSent) {
      setFormSent(true);
    }
  };

  return (
    <>
      {(loadingLogin || loading) && <section className='spinner'></section>}
      <form
        onSubmit={handleSubmit(onSubmit)}
        autoComplete='off'
        className='signUpForm'>
        <section>{error && <p className='error'>{error}</p>}</section>
        {/* Name */}
        <section className={`${errors.firstName ? 'wrong' : ''} half left`}>
          <input
            type='text'
            {...register('firstName', {
              maxLength: { value: 50, message: 'Nome troppo lungo' },
              required: 'Campo obbligatorio',
            })}
            id='firstName'
            autoComplete='one-time-code'
            placeholder=' '
          />
          <label htmlFor='firstName'>Nome</label>

          {errors.firstName && (
            <p className='error'>{errors.firstName.message}</p>
          )}
        </section>

        {/* Cognome */}
        <section className={`${errors.lastName ? 'wrong' : ''} half right`}>
          <input
            type='text'
            {...register('lastName', {
              maxLength: { value: 50, message: 'Cognome troppo lungo' },
              required: 'Campo obbligatorio',
            })}
            id='lastName'
            placeholder=' '
          />
          <label htmlFor='lastName'>Cognome</label>

          {errors.lastName && (
            <p className='error'>{errors.lastName.message}</p>
          )}
        </section>

        {/* Email */}
        <section className={`${errors.email ? ' wrong' : ''}`}>
          <input
            type='text'
            {...register('email', {
              pattern: {
                value: emailRegExp,
                message: 'Email non valida',
              },
              maxLength: { value: 150, message: 'Email too long' },
              required: 'Campo obbligatorio',
            })}
            id='email'
            utoComplete='one-time-code'
            placeholder=' '
            onChange={(e) => trimValue(e)}
          />
          <label htmlFor='email'>Email</label>
          {errors.email && <p className='error'>{errors.email.message}</p>}
        </section>

        {/* Phone */}
        <section className={`${errors.phone ? ' wrong' : ''}`}>
          <input
            type='text'
            {...register('phone', {
              pattern: {
                value: phoneRegExp,
                message: 'Questo numero non è valido',
              },
              required: 'Campo obbligatorio',
            })}
            id='phone'
            autoComplete='one-time-code'
            placeholder=' '
            onChange={(e) => trimValue(e)}
          />
          <label htmlFor='phone'>Numero di telefono</label>
          {errors.phone && <p className='error'>{errors.phone.message}</p>}
        </section>

        {/* Password */}
        <section className={`${errors.password ? 'wrong' : ''}`}>
          <input
            type='password'
            {...register('password', {
              required: 'Campo obbligatorio',
              minLength: {
                value: 8,
                message: 'Password troppo corta: minimo 8 caratteri',
              },
            })}
            id='password'
            autoComplete='one-time-code'
            placeholder=' '
          />
          <label htmlFor='password'>Password</label>
          <small>Minimo 8 caratteri</small>

          {errors.password && (
            <p className='error'>{errors.password.message}</p>
          )}
        </section>

        <section className='checkbox'>
          <input
            type='checkbox'
            {...register('privacyPolicy', {
              required: 'Per iscriverti devi accetare la Privacy Policy',
            })}
            id='privacyPolicy'
            placeholder=' '
          />
          <label
            htmlFor='privacyPolicy'
            id='privacyPolicyLabel'
            className='checkboxLabel'>
            Ho letto e accetto la{' '}
            <Link
              to='../docs/privacy-policy'
              title='Privacy Policy'
              target='_blank'
              rel='noreferrer'>
              Privacy Policy
            </Link>{' '}
            e i{' '}
            <Link
              to='../docs/terms'
              title='Termini e Condizioni'
              target='_blank'
              rel='noreferrer'>
              Termini
            </Link>
          </label>
          {errors.privacyPolicy && (
            <p className='error'>{errors.privacyPolicy.message}</p>
          )}
        </section>

        <input type='submit' value='Registrati' className='btn' />
      </form>
    </>
  );
};

export default SignUpForm;
