import React from 'react';
import Title from '../../components/Title';

const Cookie = () => {
  return (
    <>
      <Title title='Cookie' />
      <main className='cookiePage'>
        <h1>Cookie Policy</h1>
        <p>
          Il sito web di Resistance utilizza i cookie per migliorare
          l'esperienza di navigazione degli utenti. Ci teniamo a voi, e per
          questo noi utilizziamo solo <b>cookie tecnici</b>.
        </p>

        <a
          href='https://www.iubenda.com/privacy-policy/94674002/cookie-policy'
          className='btn'
          title='Cookie Policy '>
          Cookie Policy
        </a>
        <div
          dangerouslySetInnerHTML={{
            __html: `<script type="text/javascript">(function (w,d) {var loader = function () {var s = d.createElement("script"), tag = d.getElementsByTagName("script")[0]; s.src="https://cdn.iubenda.com/iubenda.js"; tag.parentNode.insertBefore(s,tag);}; if(w.addEventListener){w.addEventListener("load", loader, false);}else if(w.attachEvent){w.attachEvent("onload", loader);}else{w.onload = loader;}})(window, document);</script>`,
          }}
        />
      </main>
    </>
  );
};

export default Cookie;
