import backend from '../../api/backend';
import {
  // Sign up
  USER_SIGNUP_REQUEST,
  USER_SIGNUP_SUCCESS,
  USER_SIGNUP_FAIL,

  // Login
  USER_LOGIN_REQUEST,
  USER_LOGIN_SUCCESS,
  USER_LOGIN_FAIL,

  // Refresh
  REFRESH_LOGIN_REQUEST,
  REFRESH_LOGIN_SUCCESS,
  REFRESH_LOGIN_FAIL,

  // Logout
  USER_LOGOUT_REQUEST,
  USER_LOGOUT_SUCCESS,
  USER_LOGOUT_FAIL,

  // Details
  USER_DETAILS_REQUEST,
  USER_DETAILS_SUCCESS,
  USER_DETAILS_FAIL,

  // Restore password email
  RESTORE_PASSWORD_EMAIL_REQUEST,
  RESTORE_PASSWORD_EMAIL_SUCCESS,
  RESTORE_PASSWORD_EMAIL_FAIL,

  // Reset password
  RESET_PASSWORD_REQUEST,
  RESET_PASSWORD_SUCCESS,
  RESET_PASSWORD_FAIL,

  // Update profile
  UPDATE_PROFILE_REQUEST,
  UPDATE_PROFILE_SUCCESS,
  UPDATE_PROFILE_FAIL,
  UPDATE_PASSWORD_REQUEST,
  UPDATE_PASSWORD_SUCCESS,
  UPDATE_PASSWORD_FAIL,
  DELETE_ACCOUNT_REQUEST,
  DELETE_ACCOUNT_SUCCESS,
  DELETE_ACCOUNT_FAIL,
  SOCIAL_LOGIN_REQUEST,
  SOCIAL_LOGIN_SUCCESS,
  SOCIAL_LOGIN_FAIL,
  GET_USER_ORDERS_REQUEST,
  GET_USER_ORDERS_SUCCESS,
  GET_USER_ORDERS_FAIL,
  GET_USER_TICKETS_REQUEST,
  GET_USER_TICKETS_SUCCESS,
  GET_USER_TICKETS_FAIL,
} from '../../constants/userConstants';
import { createConfig, createConfigAccount, formatError } from './utils';

export const getUserDetails = () => async (dispatch, getState) => {
  try {
    dispatch({ type: USER_DETAILS_REQUEST });
    let accessToken = getState().userLogin?.user?.access_token;
    const config = createConfigAccount(accessToken);
    const { data } = await backend.get('/users/profile/', config);
    dispatch({ type: USER_DETAILS_SUCCESS, payload: data });
  } catch (error) {
    dispatch({
      type: USER_DETAILS_FAIL,
      payload: formatError(error),
    });
  }
};

export const signUp = (formData) => async (dispatch) => {
  try {
    dispatch({ type: USER_SIGNUP_REQUEST });
    const config = createConfig();
    const { firstName, lastName, email, phone, password, privacyPolicy } =
      formData;

    let { data } = await backend.post(
      '/users/signup/',
      { firstName, lastName, email, password, phone, privacyPolicy },
      config
    );
    dispatch({ type: USER_SIGNUP_SUCCESS, payload: data });
  } catch (error) {
    dispatch({
      type: USER_SIGNUP_FAIL,
      payload: formatError(error),
    });
  }
};

export const login = (email, password) => async (dispatch) => {
  try {
    dispatch({ type: USER_LOGIN_REQUEST });

    const config = createConfig();

    let { data } = await backend.post(
      '/users/login/',
      { email, password },
      config
    );
    dispatch({ type: USER_LOGIN_SUCCESS, payload: data });
  } catch (error) {
    console.log(error);
    dispatch({
      type: USER_LOGIN_FAIL,
      payload: formatError(error),
    });
  }
};

export const googleLogin = (response) => async (dispatch) => {
  try {
    dispatch({ type: SOCIAL_LOGIN_REQUEST });

    const config = createConfig();
    let { data } = await backend.post(
      '/users/login/social/',
      { ...response, backend: 'google-oauth2' },
      config
    );
    dispatch({ type: SOCIAL_LOGIN_SUCCESS, payload: data });
  } catch (error) {
    dispatch({
      type: SOCIAL_LOGIN_FAIL,
      payload: formatError(error),
    });
  }
};
export const appleLogin = (response) => async (dispatch) => {
  try {
    dispatch({ type: SOCIAL_LOGIN_REQUEST });

    const config = createConfig();

    let { data } = await backend.post(
      '/users/login/social/',
      { ...response, backend: 'apple' },
      config
    );
    dispatch({ type: SOCIAL_LOGIN_SUCCESS, payload: data });
  } catch (error) {
    dispatch({
      type: SOCIAL_LOGIN_FAIL,
      payload: formatError(error),
    });
  }
};

export const refreshLogin = () => async (dispatch) => {
  try {
    dispatch({ type: REFRESH_LOGIN_REQUEST });
    const config = createConfig();
    let { data } = await backend.post('/users/login/refresh/', {}, config);
    dispatch({ type: REFRESH_LOGIN_SUCCESS, payload: data });
  } catch (error) {
    dispatch({
      type: REFRESH_LOGIN_FAIL,
      payload: formatError(error),
    });
  }
};

export const logout = () => async (dispatch) => {
  try {
    dispatch({ type: USER_LOGOUT_REQUEST });
    const config = createConfig();
    let { data } = await backend.post('/users/logout/', {}, config);
    dispatch({ type: USER_LOGOUT_SUCCESS, payload: data });
  } catch (error) {
    dispatch({
      type: USER_LOGOUT_FAIL,
      payload: formatError(error),
    });
  }
};

export const restorePasswordRequest = (email) => async (dispatch) => {
  try {
    dispatch({ type: RESTORE_PASSWORD_EMAIL_REQUEST });
    const config = createConfig();

    let { data } = await backend.post(
      '/auth/password_reset/',
      { email },
      config
    );
    dispatch({ type: RESTORE_PASSWORD_EMAIL_SUCCESS, payload: data });
  } catch (error) {
    dispatch({
      type: RESTORE_PASSWORD_EMAIL_FAIL,
      payload: formatError(error),
    });
  }
};

export const resetPassword = (password, token) => async (dispatch) => {
  try {
    dispatch({ type: RESET_PASSWORD_REQUEST });
    const config = {
      header: {
        'Content-type': 'application/json',
      },
      withCredentials: true,
    };

    let { data } = await backend.post(
      '/auth/password_reset/confirm/',
      { password, token },
      config
    );
    dispatch({ type: RESET_PASSWORD_SUCCESS, payload: data });
  } catch (error) {
    dispatch({
      type: RESET_PASSWORD_FAIL,
      payload: formatError(error),
    });
  }
};

export const updateProfile = (formData) => async (dispatch, getState) => {
  try {
    dispatch({ type: UPDATE_PROFILE_REQUEST });
    let accessToken = getState().userLogin?.user?.access_token;
    const config = createConfigAccount(accessToken);

    let { data } = await backend.post(
      '/account/update/profile/',
      { ...formData },
      config
    );
    dispatch({ type: UPDATE_PROFILE_SUCCESS, payload: data });
  } catch (error) {
    dispatch({
      type: UPDATE_PROFILE_FAIL,
      payload: formatError(error),
    });
  }
};

export const updatePassword = (formData) => async (dispatch, getState) => {
  try {
    dispatch({ type: UPDATE_PASSWORD_REQUEST });
    let accessToken = getState().userLogin?.user?.access_token;
    const config = createConfigAccount(accessToken);

    let { data } = await backend.post(
      '/account/update/password/',
      { ...formData },
      config
    );
    dispatch({ type: UPDATE_PASSWORD_SUCCESS, payload: data });
  } catch (error) {
    dispatch({
      type: UPDATE_PASSWORD_FAIL,
      payload: formatError(error),
    });
  }
};

export const deleteAccount = (formData) => async (dispatch, getState) => {
  try {
    dispatch({ type: DELETE_ACCOUNT_REQUEST });
    let accessToken = getState().userLogin?.user?.access_token;
    const config = createConfigAccount(accessToken);

    let { data } = await backend.post(
      '/account/update/delete/',
      { ...formData },
      config
    );
    dispatch({ type: DELETE_ACCOUNT_SUCCESS, payload: data });
  } catch (error) {
    dispatch({
      type: DELETE_ACCOUNT_FAIL,
      payload: formatError(error),
    });
  }
};

export const getUserOrders = () => async (dispatch, getState) => {
  try {
    dispatch({ type: GET_USER_ORDERS_REQUEST });
    let accessToken = getState().userLogin?.user?.access_token;
    const config = createConfigAccount(accessToken);

    let { data } = await backend.get('/users/profile/orders/', config);
    dispatch({ type: GET_USER_ORDERS_SUCCESS, payload: data });
  } catch (error) {
    dispatch({
      type: GET_USER_ORDERS_FAIL,
      payload: formatError(error),
    });
  }
};

export const getUserTickets = () => async (dispatch, getState) => {
  try {
    dispatch({ type: GET_USER_TICKETS_REQUEST });
    let accessToken = getState().userLogin?.user?.access_token;
    const config = createConfigAccount(accessToken);

    let { data } = await backend.get('/users/profile/tickets/', config);
    dispatch({ type: GET_USER_TICKETS_SUCCESS, payload: data });
  } catch (error) {
    dispatch({
      type: GET_USER_TICKETS_FAIL,
      payload: formatError(error),
    });
  }
};
