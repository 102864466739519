import { Route, Routes } from 'react-router-dom';

import { useDispatch } from 'react-redux';
import { useEffect } from 'react';
import { refreshLogin } from './redux/actions/userActions';
import ScrollToTop from './components/ScroolToTop';

import Store from './pages/store/Store';
import Product from './pages/store/Product';
import Cart from './pages/store/Cart';
import Checkout from './pages/store/Checkout';
import Account from './pages/account/Account';
import Login from './pages/account/Login';
import SignUp from './pages/account/SignUp';
import RestorePwdForm from './pages/account/RestorePwdForm';
import RestorePwd from './pages/account/RestorePwd';
import AccountSettings from './pages/account/AccountSettings';
import Ticket from './pages/store/Ticket';
import TicketTappa from './pages/store/TicketTappa';
import Insurance from './pages/store/Insurance';
import Home from './pages/Home';
import Confirmed from './pages/store/Confirmed';
import Header from './layout/Header';
import Footer from './layout/Footer';
import Competizioni from './pages/info/Competizioni';
import Regolamento from './pages/info/Regolamento';
import HallOfFame from './pages/info/HallOfFame';
import Privacy from './pages/docs/Privacy';
import Cookie from './pages/docs/Cookie';
import TerminiECondizioni from './pages/docs/TerminiECondizioni';
import Best from './pages/info/Best';
import Contact from './pages/info/Contact';
import AthleteInfo from './pages/store/AthleteInfo';
import TicketsTappa from './pages/store/TicketsTappa';
import AdminEvents from './pages/admin/Events';
import AdminEvent from './pages/admin/Event';
import AdminTicket from './pages/admin/Ticket';

function App() {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(refreshLogin());
  }, [dispatch]);

  return (
    <>
      <ScrollToTop />
      <Header />
      <Routes>
        <Route path='/' exact element={<Home />} />
        <Route path='/home' exact element={<Home />} />

        {/* INFO */}
        <Route path='/competizioni' exact element={<Competizioni />} />
        <Route path='/regolamento' exact element={<Regolamento />} />
        <Route path='/hallOfFame' exact element={<HallOfFame />} />
        <Route path='/best' exact element={<Best />} />
        <Route path='/contatti' exact element={<Contact />} />

        {/* DOCS */}
        <Route path='/docs/privacy-policy' exact element={<Privacy />} />
        <Route path='/docs/cookie' exact element={<Cookie />} />
        <Route path='/docs/terms' exact element={<TerminiECondizioni />} />

        {/* STORE */}
        <Route path='/store' exact element={<Store />} />
        <Route path='/store/product/:product_id' element={<Product />} />
        <Route path='/store/ticket' exact element={<Ticket />} />
        <Route path='/store/tickets/:tappa' element={<TicketsTappa />} />
        <Route path='/store/ticket/:eventId' element={<TicketTappa />} />
        <Route
          path='/store/ticket/:eventId/athlete_info'
          element={<AthleteInfo />}
        />
        <Route path='/store/cart' element={<Cart />} />
        <Route path='/store/checkout/*' element={<Checkout />} />
        <Route path='/store/confirmed' element={<Confirmed />} />

        <Route element={<Login />} path='/login' exact />
        <Route element={<SignUp />} path='/signup' exact />
        <Route element={<RestorePwd />} path='/auth/restore_password' exact />
        <Route element={<RestorePwdForm />} path='/auth/password_reset' exact />

        {/* Account pages */}
        <Route element={<Account />} path='/account' exact />
        <Route element={<Insurance />} path='/account/insurance' exact />
        <Route element={<AccountSettings />} path='/account/settings/*' />

        {/* Admin */}
        <Route element={<AdminEvents />} path='/admin/events' exact />
        <Route element={<AdminEvent />} path='/admin/events/:eventId' />
        <Route
          element={<AdminTicket />}
          path='/admin/events/:eventId/:ticketId'
        />
      </Routes>
      <Footer />
    </>
  );
}

export default App;
