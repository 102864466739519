import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import { HashRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { GoogleOAuthProvider } from '@react-oauth/google';

import store from './redux/store';
import './style/main.css';

export const DEBUG = window.location.origin === 'http://localhost:3000';

// import store from './redux/store';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <GoogleOAuthProvider clientId='93449391734-v69d6r0i42r90l7mucukj7dsddmfchkp.apps.googleusercontent.com'>
    <React.StrictMode>
    <Provider store={store}>
      <HashRouter>
        <App />
      </HashRouter>
    </Provider>
    </React.StrictMode>
  </GoogleOAuthProvider>
);
