import React, { useEffect, useRef } from 'react';
import Title from '../../components/Title';

import Logo from '../../images/logo1000.png';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import { sendContactForm } from '../../redux/actions/infoActions';
import { emailRegExp } from '../../constants/regExp';

import { AiOutlineInstagram } from 'react-icons/ai';
import { FaWhatsapp } from 'react-icons/fa';
import { PiPhoneLight } from 'react-icons/pi';
import { AiOutlineMail } from 'react-icons/ai';
import { Link } from 'react-router-dom';

const Contact = () => {
  const { message, error, loading } = useSelector((state) => state.contact);
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();
  const dispatch = useDispatch();
  const onSubmit = (data) => {
    dispatch(sendContactForm(data));
    if (message) {
      reset();
    }
  };
  const refs = [
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
  ];

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            entry.target.classList.add('slide-in');
            observer.unobserve(entry.target);
          }
        });
      },
      { threshold: [0, 0.25] } // trigger when 50% of the element is visible
    );

    refs.forEach((ref) => {
      if (ref.current) {
        observer.observe(ref.current);
      }
    });

    return () => {
      refs.forEach((ref) => {
        if (ref.current) {
          observer.unobserve(ref.current);
        }
      });
    };
  });
  return (
    <>
      <Title title={'Contatti'} />
      <main className='contattiPage'>
        <section className='head'>
          <img className='logo' src={Logo} alt='' />
          <p>CHAMPIONSHIP, SPORTSWEAR AND EQUIPMENT</p>
        </section>
        <section className='body'>
          <section className='contacts'>
            <h3>Puoi contattarci qui:</h3>
            <div className='slide left ' ref={refs[0]}>
              <p>
                <AiOutlineMail />
                <a href='mailto:resistance.srl@gmail.com'>
                  resistance.srl@gmail.com
                </a>
              </p>

              <p>
                <PiPhoneLight />
                Tel: +39 320 265 4382
              </p>
              <p>
                <FaWhatsapp />
                Whatsapp: +39 320 265 4382
              </p>
              <p>
                <Link
                  to='https://www.instagram.com/resistance.italia/'
                  target='_blank'
                  rel='noreferrer'>
                  <AiOutlineInstagram /> resistance.italia
                </Link>
              </p>
            </div>
          </section>
          <section className='contactForm'>
            <h3>O lasciarci un messaggio:</h3>
            {error && <p className='error'>{error}</p>}
            {loading && <div className='spinner' />}
            {message && <p className='success'>{message}</p>}
            <form
              onSubmit={handleSubmit(onSubmit)}
              className='slide right'
              ref={refs[4]}>
              <section className={`${errors?.name ? 'wrong' : ''}`}>
                <input
                  type='text'
                  {...register('name', {
                    maxLength: { value: 50, message: 'Nome troppo lungo' },
                    required: 'Campo obbligatorio',
                  })}
                  id='name'
                  autoComplete='one-time-code'
                  placeholder=' '
                />
                <label htmlFor='name'>Nome</label>

                {errors?.name && <p className='error'>{errors.name.message}</p>}
              </section>

              <section className={`${errors.email ? 'wrong' : ''}`}>
                <input
                  type='text'
                  {...register('email', {
                    pattern: {
                      value: emailRegExp,
                      message: 'Email non valida',
                    },
                    maxLength: { value: 150, message: 'Email troppo lunga' },
                    required: 'Campo obbligatorio',
                  })}
                  id='email'
                  autoComplete='off'
                  placeholder=' '
                />
                <label htmlFor='email'>Email</label>
                {errors?.email && (
                  <p className='error'>{errors.email.message}</p>
                )}
              </section>

              <section className={`${errors.email ? 'wrong' : ''}`}>
                <textarea
                  {...register('message', {
                    required: 'Campo obbligatorio',
                  })}
                  placeholder=''
                />
                <label htmlFor='message'>Messaggio</label>
                {errors?.message && (
                  <p className='error'>{errors.message.message}</p>
                )}
              </section>

              <input type='submit' className='btn' value={'Invia'} />
            </form>
          </section>
        </section>
      </main>
    </>
  );
};

export default Contact;
