import React from 'react';
import { useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';

const ModelInput = ({ p_type_id, product }) => {
  // const pType = useSelector((state) =>
  //   state.store.products.find((pType) => pType.id === p_type_id)
  // );
  const pTypes = useSelector((state) => state.store.products);
  const filteredPTypes = useSelector((state) =>
    state.store.products.filter((pType) => pType.id === p_type_id)
  );

  const renderModels = () => {
    return filteredPTypes.map((pType) => {
      return pType.versions
        ?.filter((version) => version.size === product.size)
        ?.map((version) => {
          return (
            <NavLink
              className={`${product.model === version.model && 'active'}`}
              key={version.id}
              to={`../store/product/${version.id}`}>
              {version.model}
            </NavLink>
          );
        });
    });
  };

  const renderArnicaModels = () => {
    return pTypes
      ?.filter((pType) => pType.name.includes('Arnica'))
      .map((pType) => {
        return pType.versions
          ?.filter((version) => version.size === product.size)
          ?.map((version) => {
            return (
              <NavLink
                className={`${product.model === version.model && 'active'}`}
                key={version.id}
                to={`../store/product/${version.id}`}>
                {version.model}
              </NavLink>
            );
          });
      });
  };

  return (
    <nav className='modelInput'>
      {!product?.name?.includes('Arnica') && renderModels()}
      {product?.name?.includes('Arnica') && renderArnicaModels()}
    </nav>
  );
};

export default ModelInput;
