import React, { useEffect, useState } from 'react';
import { Link, Navigate, useParams } from 'react-router-dom';
import Protected from '../../components/Protected';
import { useDispatch, useSelector } from 'react-redux';
import { createTicket, getEvent } from '../../redux/actions/eventActions';
import { getUserDetails } from '../../redux/actions/userActions';
import Price from '../../components/store/Price';
import { getCart } from '../../redux/actions/storeActions';
import Title from '../../components/Title';
import { calcDiscountedPrice } from '../../components/store/utils';
import EventsCarousel from '../../layout/store/EventsCarousel';

const TicketTappa = () => {
  const { eventId } = useParams();
  const { event } = useSelector((state) => state.event);
  const { user } = useSelector((state) => state.userDetails);
  const { tickets, error } = useSelector((state) => state.cart);

  const [tappaImage, setTappaImage] = useState(null);

  useEffect(() => {
    const loadTappaImage = async () => {
      if (!event || !event?.tappa) {
        return;
      }
      let imageImport;
      if (event?.tappa && event?.tipo) {
        imageImport = () =>
          import(`../../images/events/${event.tappa}/${event.tipo}.jpg`);
      } else if (event?.tappa) {
        imageImport = () =>
          import(`../../images/events/${event.tappa}/base.jpg`);
      }
      if (imageImport) {
        const imageModule = await imageImport();
        setTappaImage(imageModule.default);
      }
    };
    loadTappaImage();
  });

  const [formSent, setFormSent] = useState(false);

  const checkInsurance = () => {
    for (let i = 0; i < tickets?.length; i++) {
      if (tickets[i].insurance) {
        return true;
      }
    }
    return false;
  };

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getUserDetails());
    dispatch(getEvent(eventId));
    dispatch(getCart());
  }, [dispatch, eventId]);

  const onClick = (e) => {
    dispatch(createTicket(eventId));
    dispatch(getCart());
    setFormSent(true);
  };

  return (
    <>
      {event?.active && (
        <main className='ticketTappa'>
          <div className='ticketRecap'>
            <Title title={`Biglietto ${event.tappa} ${event.tipo}`} />
            <Protected />
            {!event?.active && <Navigate to='../competizioni' />}

            {tickets?.some(
              (ticket) =>
                ticket.tappa === event.tappa && ticket.tipo === event.tipo
            ) &&
              formSent &&
              (event?.needs_athletes_info ? (
                <Navigate to={`../store/ticket/${eventId}/athlete_info/`} />
              ) : event?.needs_insurance &&
                !user?.insurance &&
                !checkInsurance() ? (
                  <Navigate to='../account/insurance' />
                  ) : (
                <Navigate to='../store/cart' />
              ))}
            {error && <p className='error'>{error}</p>}
            <h1>
              Ticket Tappa {event.tappa} <span>{event.tipo}</span>
            </h1>
            <p>
              Acquista il tuo biglietto per la tappa {event.tappa} {event.tipo}
            </p>
            <p>{event.info}</p>
            <div className='content'>
              <img src={tappaImage} alt='' />
              <div className='text'>
                <section className='ticket'>
                  <h2>Biglietto</h2>
                  <div className='costo'>
                    Costo biglietto:{' '}
                    <Price
                      amount={calcDiscountedPrice(event)}
                      className='price'
                    />
                    {event?.discount && (
                      <div className='original'>
                        <Price amount={event.price} />
                      </div>
                    )}
                  </div>
                </section>

                {event?.tappa === 'Assoluto' && (
                  <p className='textAssoluto'>
                    Biglietto VIP, ticket limitati.
                    <br /> Include un posto a sedere nominale e una t-shirt
                    esclusiva stagione 2024
                  </p>
                )}

                {event.needs_insurance &&
                  !user?.insurance &&
                  !checkInsurance() && (
                    <>
                      <section className='insurance'>
                        <h2>Assicurazione</h2>
                        <p>
                          Per partecipare ai nostri eventi devi essere coperto
                          da un'assicurazione. Il costo è di <b>30€</b>, esclusi
                          dall'acquisto del biglietto. Ha validità di{' '}
                          <b>1 anno</b> e ti permetterà di partecipare a tutti
                          gli eventi organizzati da <b>Resistance</b>.
                        </p>
                        <div className='costo'>
                          Costo assicurazione:{' '}
                          <Price amount={30} className='price'></Price>
                        </div>
                      </section>
                      <section className='total'>
                        <h2>Totale</h2>
                        <p>
                          Il totale è composto dal costo del biglietto e
                          dell'assicurazione.
                        </p>
                        <div className='costo'>
                          Totale:{' '}
                          <Price
                            amount={calcDiscountedPrice(event) + 30}
                            className='price'></Price>
                        </div>
                      </section>
                    </>
                  )}

                {tickets?.some(
                  (ticket) =>
                    ticket.tappa === event.tappa && ticket.tipo === event.tipo
                ) ? (
                  <p className='btn'>
                    <Link to={'../store/cart'}>Biglietto nel carrello</Link>
                  </p>
                ) : tickets?.some((ticket) => ticket.tappa === event.tappa) ? (
                  <p className='btn'>
                    <Link to={'../store/cart'}>
                      Biglietto per l'evento nel carrello
                    </Link>
                  </p>
                ) : (
                  <button
                    className='btn'
                    onClick={(e) => onClick(e)}
                    disabled={event.available_places === 0}>
                    {event.available_places === 0
                      ? 'Sold Out'
                      : 'Aggiungi al carrello'}
                  </button>
                )}
              </div>
            </div>
            <Link className='tornaShop' to='../store'>
              Torna allo Shop
            </Link>
          </div>
          <EventsCarousel />
        </main>
      )}
    </>
  );
};

export default TicketTappa;
