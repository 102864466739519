import React from 'react';
import { useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';

const SizeInput = ({ p_type_id, product }) => {
  const pTypes = useSelector((state) =>
    state.store.products.filter((pType) => pType.id === p_type_id)
  );

  const sizes = { XS: 0, S: 1, M: 2, L: 3, XL: 4, XXL: 5, XXXL: 6 };

  function compareSizes(a, b) {
    if (sizes[a.size] < sizes[b.size]) {
      return -1;
    } else if (sizes[a.size] > sizes[b.size]) {
      return 1;
    }
    // a must be equal to b
    return 0;
  }

  const renderSizes = () => {
    return pTypes.map((pType) => {
      return pType.versions
        ?.filter((version) => version.model === product.model)
        ?.sort(compareSizes)
        ?.map((version) => {
          return (
            <NavLink
              className={`${product.size === version.size && 'active'}`}
              key={version.id}
              to={`../store/product/${version.id}`}>
              {version.size}
            </NavLink>
          );
        });
    });
  };

  return <nav className='sizeInput'>{renderSizes()}</nav>;
};

export default SizeInput;
