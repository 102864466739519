import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getUserOrders } from '../../redux/actions/userActions';
import { MdOutlineArrowDropDownCircle } from 'react-icons/md';
import OrderItem from '../store/OrderItem';
import TicketOrderItem from '../store/TicketOrderItem';
import Price from '../store/Price';

const UserOrders = () => {
  const { orders } = useSelector((state) => state.userDetails);
  let [open, setOpen] = useState(false);

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getUserOrders());
  }, [dispatch]);

  const renderOrders = (orders) => {
    return orders?.map((order, index) => (
      <>
        <div className='order' key={index}>
          <div className='info'>
            <h4>#{order?.details?.code}</h4>
            <small>
              {order?.details?.date
                ? new Date(order.details.date).toLocaleDateString('it-IT')
                : ''}
            </small>{' '}
          </div>
          <div>
            {order?.cart?.products?.map((item) => (
              <OrderItem item={item} key={item.id} />
            ))}
            {order?.cart?.tickets?.map((ticket) => (
              <TicketOrderItem ticket={ticket} key={ticket.id} />
            ))}
          </div>
          <hr />
          {order?.details && (
            <div className='subtotale'>
              <div>
                Subtotale: <Price amount={order?.details?.subtotal} />
              </div>
              <div>
                Spedizione:{' '}
                {Number(order?.details?.shipping_cost) === 0.0 ? (
                  <p>Gratuita</p>
                ) : (
                  <Price amount={order?.details?.shipping_cost} />
                )}
              </div>
            </div>
          )}
          <hr />
          <div className='totale'>
            {order?.details && (
              <div>
                Totale:
                <Price amount={order?.details?.total} />
              </div>
            )}
          </div>
        </div>
      </>
    ));
  };

  return (
    <>
      {orders?.length > 0 && (
        <section className='orders'>
          <div className='showRecap'>
            <h3 className='text'>I Miei Ordini</h3>
            <MdOutlineArrowDropDownCircle
              onClick={() => setOpen(!open)}
              className='arrow'
            />
          </div>
          {open && orders && renderOrders(orders)}
        </section>
      )}
    </>
  );
};

export default UserOrders;
