import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import { getAdminInfo } from '../../redux/actions/eventActions';
import Protected from '../../components/Protected';

const AdminTicket = () => {
  const dispatch = useDispatch();
  const { eventId, ticketId } = useParams();
  const { events } = useSelector((state) => state.adminInfo);
  const event = events?.filter((event) => event.id === Number(eventId))[0];
  const ticket = event?.tickets?.filter(
    (ticket) => ticket.id === Number(ticketId)
  )[0];

  useEffect(() => {
    dispatch(getAdminInfo());
  }, [dispatch]);

  const estraiDataDiNascita = (codiceFiscale) => {
    const annoPartial = codiceFiscale.substr(6, 2);
    const anno = annoPartial < 22 ? '20' + annoPartial : '19' + annoPartial;
    const mese = 'ABCDEHLMPRST'.indexOf(codiceFiscale[8].toUpperCase());
    let giorno = parseInt(codiceFiscale.substr(9, 2), 10);
    if (giorno > 40) giorno -= 40; // Correzione per le donne

    return new Date(anno, mese, giorno);
  };

  return (
    <>
      <Protected />
      {ticket && (
        <main>
          <h1>{event.name}</h1>
          <h2>
            {ticket?.user?.first_name} {ticket?.user?.last_name}
          </h2>
          <p>Email: {ticket?.user?.email}</p>
          <p>Telefono: {ticket?.user?.phone}</p>
          {event.needs_insurance && (
            <>
              <p>Codice fiscale: {ticket?.insurance?.codice_fiscale}</p>
              <p>
                Data di nascita:{' '}
                {ticket?.insurance?.codice_fiscale &&
                  estraiDataDiNascita(
                    ticket?.insurance?.codice_fiscale
                  )?.toLocaleDateString()}
              </p>
            </>
          )}
          {event.needs_athletes_info && (
            <>
              <p>Classe: {ticket.athlete_info.classe}</p>
              <p>
                Problemi di salute:{' '}
                {ticket?.athlete_info?.salute ? (
                  <span>Sì</span>
                ) : (
                  <span>No</span>
                )}
              </p>
              <p>
                Necessita alloggio:{' '}
                {ticket?.athlete_info?.alloggio ? (
                  <span>Sì</span>
                ) : (
                  <span>No</span>
                )}
              </p>
            </>
          )}
          <h2>Dati per la fattura:</h2>
          <p>Nome: {ticket?.invoice?.first_name}</p>
          <p>Cognome: {ticket?.invoice?.last_name}</p>
          <p>Stato: {ticket?.invoice?.state}</p>
          <p>Provincia: {ticket?.invoice?.provincia}</p>
          <p>Città: {ticket?.invoice?.city}</p>
          <p>CAP: {ticket?.invoice?.CAP}</p>
          <p>Indirizzo: {ticket?.invoice?.address}</p>
          <p>Info aggiuntive: {ticket?.invoice?.info}</p>
          <div>
            <Link
              className='adminEvents__back'
              to={`../admin/events/${event.id}`}>
              indietro
            </Link>
          </div>
        </main>
      )}
    </>
  );
};

export default AdminTicket;
