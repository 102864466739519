import {
  ADD_PRODUCT_TO_CART_FAILED,
  ADD_PRODUCT_TO_CART_REQUEST,
  ADD_PRODUCT_TO_CART_SUCCESS,
  APPLY_DISCOUNT_FAILED,
  APPLY_DISCOUNT_REQUEST,
  APPLY_DISCOUNT_SUCCESS,
  CONFIRM_PAYMENT_FAILED,
  CONFIRM_PAYMENT_REQUEST,
  CONFIRM_PAYMENT_SUCCESS,
  GET_CART_FAILED,
  GET_CART_REQUEST,
  GET_CART_SUCCESS,
  GET_ORDER_FAILED,
  GET_ORDER_REQUEST,
  GET_ORDER_SUCCESS,
  GET_PAYMENT_INTENT_FAILED,
  GET_PAYMENT_INTENT_REQUEST,
  GET_PAYMENT_INTENT_SUCCESS,
  GET_PRODUCT_FAILED,
  GET_PRODUCT_REQUEST,
  GET_PRODUCT_SUCCESS,
  REMOVE_COUPON_FAILED,
  REMOVE_COUPON_REQUEST,
  REMOVE_COUPON_SUCCESS,
  REMOVE_PRODUCT_FROM_CART_FAILED,
  REMOVE_PRODUCT_FROM_CART_REQUEST,
  REMOVE_PRODUCT_FROM_CART_SUCCESS,
  SEND_EMAIL_FAILED,
  SEND_EMAIL_REQUEST,
  SEND_EMAIL_SUCCESS,
  SEND_INVOICE_FAILED,
  SEND_INVOICE_REQUEST,
  SEND_INVOICE_SUCCESS,
  SEND_SHIPPING_FAILED,
  SEND_SHIPPING_REQUEST,
  SEND_SHIPPING_SUCCESS,
  STORE_FAILED,
  STORE_REQUEST,
  STORE_SUCCESS,
} from '../../constants/storeCostants';
import backend from '../../api/backend';
import { createConfig, createConfigAccount, formatError } from './utils';

export const getStore = () => async (dispatch) => {
  try {
    dispatch({ type: STORE_REQUEST });
    const config = createConfig();
    const { data } = await backend.get('/store/', config);
    dispatch({ type: STORE_SUCCESS, payload: data });
  } catch (error) {
    dispatch({
      type: STORE_FAILED,
      payload: formatError(error),
    });
  }
};

export const getProduct = (id) => async (dispatch) => {
  try {
    dispatch({ type: GET_PRODUCT_REQUEST });
    const config = createConfig();
    const { data } = await backend.get(`/store/product/${id}/`, config);
    dispatch({ type: GET_PRODUCT_SUCCESS, payload: data });
  } catch (error) {
    dispatch({
      type: GET_PRODUCT_FAILED,
      payload: formatError(error),
    });
  }
};

export const getCart = () => async (dispatch) => {
  try {
    dispatch({ type: GET_CART_REQUEST });
    const config = createConfig();
    const { data } = await backend.get(`/store/cart`, config);
    dispatch({ type: GET_CART_SUCCESS, payload: data });
  } catch (error) {
    dispatch({
      type: GET_CART_FAILED,
      payload: formatError(error),
    });
  }
};

export const addProductToCart = (id) => async (dispatch) => {
  try {
    dispatch({ type: ADD_PRODUCT_TO_CART_REQUEST });
    const config = createConfig();
    const { data } = await backend.put(`/store/product/${id}/add/`, {}, config);
    dispatch({ type: ADD_PRODUCT_TO_CART_SUCCESS, payload: data });
  } catch (error) {
    dispatch({ type: ADD_PRODUCT_TO_CART_FAILED, payload: formatError(error) });
  }
};

export const reduceProductFromCart = (id) => async (dispatch) => {
  try {
    dispatch({ type: ADD_PRODUCT_TO_CART_REQUEST });
    const config = createConfig();
    const { data } = await backend.put(
      `/store/product/${id}/reduce/`,
      {},
      config
    );
    dispatch({ type: ADD_PRODUCT_TO_CART_SUCCESS, payload: data });
  } catch (error) {
    dispatch({ type: ADD_PRODUCT_TO_CART_FAILED, payload: formatError(error) });
  }
};

export const removeProductFromCart = (id) => async (dispatch) => {
  try {
    dispatch({ type: REMOVE_PRODUCT_FROM_CART_REQUEST });
    const config = createConfig();
    const { data } = await backend.put(
      `/store/product/${id}/remove/`,
      {},
      config
    );
    dispatch({ type: REMOVE_PRODUCT_FROM_CART_SUCCESS, payload: data });
  } catch (error) {
    dispatch({
      type: REMOVE_PRODUCT_FROM_CART_FAILED,
      payload: formatError(error),
    });
  }
};

export const getOrder = () => async (dispatch) => {
  try {
    dispatch({ type: GET_ORDER_REQUEST });
    const config = createConfig();

    let { data } = await backend.get('/store/checkout/', config);
    dispatch({ type: GET_ORDER_SUCCESS, payload: data });
  } catch (error) {
    dispatch({
      type: GET_ORDER_FAILED,
      payload: formatError(error),
    });
  }
};

export const applyDiscount = (coupon) => async (dispatch) => {
  try {
    dispatch({ type: APPLY_DISCOUNT_REQUEST });
    const config = createConfig();

    let { data } = await backend.post(
      '/store/checkout/coupon/add/',
      { coupon },
      config
    );
    dispatch({ type: APPLY_DISCOUNT_SUCCESS, payload: data });
  } catch (error) {
    dispatch({
      type: APPLY_DISCOUNT_FAILED,
      payload: formatError(error),
    });
  }
};
export const removeCoupon = () => async (dispatch) => {
  try {
    dispatch({ type: REMOVE_COUPON_REQUEST });
    const config = createConfig();
    let { data } = await backend.post(
      '/store/checkout/coupon/remove/',
      {},
      config
    );
    dispatch({ type: REMOVE_COUPON_SUCCESS, payload: data });
  } catch (error) {
    dispatch({
      type: REMOVE_COUPON_FAILED,
      payload: formatError(error),
    });
  }
};

export const sendEmailCheckout = (formData) => async (dispatch) => {
  try {
    dispatch({ type: SEND_EMAIL_REQUEST });
    const config = createConfig();

    let { data } = await backend.post(
      '/store/checkout/customer/',
      { ...formData },
      config
    );
    dispatch({
      type: SEND_EMAIL_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: SEND_EMAIL_FAILED,
      payload: formatError(error),
    });
  }
};
export const sendShipping = (formData) => async (dispatch, getState) => {
  try {
    dispatch({ type: SEND_SHIPPING_REQUEST });
    let accessToken = getState().userLogin?.user?.access_token;
    let config = createConfig();
    if (accessToken) {
      config = createConfigAccount(accessToken);
    }

    let { data } = await backend.post(
      '/store/checkout/shipping/',
      { ...formData },
      config
    );
    dispatch({
      type: SEND_SHIPPING_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: SEND_SHIPPING_FAILED,
      payload: formatError(error),
    });
  }
};

export const sendInvoice = (formData) => async (dispatch) => {
  try {
    dispatch({ type: SEND_INVOICE_REQUEST });
    const config = createConfig();

    let { data } = await backend.post(
      '/store/checkout/invoice/',
      { ...formData },
      config
    );
    dispatch({ type: SEND_INVOICE_SUCCESS, payload: data });
  } catch (error) {
    dispatch({
      type: SEND_INVOICE_FAILED,
      payload: formatError(error),
    });
  }
};

export const getPaymentIntent = () => async (dispatch) => {
  try {
    dispatch({ type: GET_PAYMENT_INTENT_REQUEST });
    const config = createConfig();

    let { data, status } = await backend.post(
      '/store/checkout/payment/intent/',
      {},
      config
    );
    if (status === 201) {
      dispatch(getPaymentIntent());
    } else {
      dispatch({ type: GET_PAYMENT_INTENT_SUCCESS, payload: data });
    }
  } catch (error) {
    dispatch({
      type: GET_PAYMENT_INTENT_FAILED,
      payload: formatError(error),
    });
  }
};

export const confirmPayment = () => async (dispatch) => {
  try {
    dispatch({ type: CONFIRM_PAYMENT_REQUEST });
    const config = createConfig();

    let { data } = await backend.post(
      '/store/checkout/payment/confirm/',
      {},
      config
    );
    dispatch({ type: CONFIRM_PAYMENT_SUCCESS, payload: data });
  } catch (error) {
    dispatch({
      type: CONFIRM_PAYMENT_FAILED,
      payload: formatError(error),
    });
  }
};
