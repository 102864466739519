import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getUserTickets } from '../../redux/actions/userActions';
import { MdOutlineArrowDropDownCircle } from 'react-icons/md';

const UserTickets = () => {
  const { tickets } = useSelector((state) => state.userDetails);
  let [open, setOpen] = useState(false);

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getUserTickets());
  }, [dispatch]);



  const [tappaImages, setTappaImages] = useState({});

  useEffect(() => {
    const IMAGES = {
      Nord: () => import(`../../images/tickets/miniatura_Nord.jpg`),
      Centro: () => import(`../../images/tickets/miniatura_Centro.jpg`),
      Sud: () => import(`../../images/tickets/miniatura_Sud.jpg`),
      Assoluto: () => import(`../../images/tickets/miniatura_Assoluto.jpg`),
    };
  
    const loadTappaImage = async (ticket) => {
      const imageImport = IMAGES[ticket.tappa];
      if (imageImport) {
        const imageModule = await imageImport();
        setTappaImages((prevImages) => ({ ...prevImages, [ticket.tappa]: imageModule }));
      }
    };
  
    if (tickets) {
      tickets.forEach(loadTappaImage);
    }
  }, [tickets]);

  const renderTickets = (tickets) => {
    return tickets.map((ticket) => {
      return <div className="ticket" key={ticket.id}>
        <img src={tappaImages[ticket.tappa]?.default} alt="" />
        <div className="content">
        <h3>Biglietto per la {ticket.event} - #{ticket.code} </h3>
        <p>{new Date(ticket.start_date).toLocaleDateString('it-IT')} - {new Date(ticket.end_date).toLocaleDateString('it-IT')} </p>
        
        <p>{ticket.location}</p>
        <p>{ticket.description}</p>
        </div>
      </div>
    })
  };

  return (
    <>
      {tickets?.length > 0 && (
        <section className='tickets'>
          <div className='showRecap'>
            <h3 className='text'>I Miei Biglietti</h3>
            <MdOutlineArrowDropDownCircle
              onClick={() => setOpen(!open)}
              className='arrow'
            />
          </div>
          {open && tickets && renderTickets(tickets)}
        </section>
      )}
    </>
  );
};

export default UserTickets;
