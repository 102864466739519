import React, { useEffect, useRef, useState } from 'react';
import Title from '../../components/Title';
import { Link, useLocation } from 'react-router-dom';
import postNord from '../../images/events/Nord/base.jpg';
import postCentro from '../../images/events/Centro/base.jpg';
import postSud from '../../images/events/Sud/base.jpg';
import assolutoMobile from '../../images/events/Assoluto/base.jpg';
import assolutoDesktop from '../../images/events/Assoluto/desktop.jpg';
import { useDispatch, useSelector } from 'react-redux';
import { getEvents } from '../../redux/actions/eventActions';
import { BiMap } from 'react-icons/bi';

const Competizioni = () => {
  const [isMobile, setIsMobile] = useState(false);
  const { events } = useSelector((state) => state.events);
  const dispatch = useDispatch();

  // Use the useLocation hook from react-router-dom to access the current location object
  const location = useLocation();

  // Extract the hash from the location object and remove the '#' at the start
  const hash = location.hash?.substring(1);

  // Define a function to smoothly scroll to a specific element by its id
  const scrollTo = (id) => {
    // Get the element by id
    const element = document.getElementById(id);
    // Scroll to the element smoothly
    element?.scrollIntoView({ behavior: 'smooth' });
  };

  // Use the useEffect hook to perform side effects
  useEffect(() => {
    // If there is a hash in the URL, scroll to the corresponding element
    if (hash) {
      scrollTo(hash);
    }
    // Dispatch the getEvents action to fetch events
    dispatch(getEvents());
  }, [dispatch, hash]); // Only re-run the effect if dispatch or hash changes

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const refs = [useRef(null), useRef(null), useRef(null), useRef(null)];

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            entry.target.classList.add('slide-in');
            observer.unobserve(entry.target);
          }
        });
      },
      { threshold: [0, 0.25] } // trigger when 50% of the element is visible
    );

    refs.forEach((ref) => {
      if (ref.current) {
        observer.observe(ref.current);
      }
    });

    return () => {
      refs.forEach((ref) => {
        if (ref.current) {
          observer.unobserve(ref.current);
        }
      });
    };
  });

  // Get event with tappa = tappa
  const getEvent = (tappa) => {
    let event = events?.find(
      (event) => event.tappa === tappa && event.tipo === 'Completa'
    );
    if (!event) event = events?.find((event) => event.tappa === tappa);
    return event;
  };

  const formatDate = (date) => {
    const d = new Date(date);
    return `${d.getDate()}/${d.getMonth() + 1}/${d.getFullYear()}`;
  };

  const renderAcquista = (tappa) => {
    const event = getEvent(tappa);
    if (!event) return null;

    if (event && event.active) {
      return (
        <Link to={`../store/tickets/${tappa}`} className='btn'>
          Acquista
        </Link>
      );
    }

    if (
      event &&
      !event.active &&
      new Date(event.ticket_end_date) < new Date()
    ) {
      return <p className='btn not-active'>Non disponibile</p>;
    }
    return (
      <p className='btn not-active'>
        Disponibile dal {formatDate(event?.ticket_start_date)}
      </p>
    );
  };

  return (
    <>
      <Title title='Competizioni' />
      <main className='competizioniPage'>
        <div className='head'>
          <h1>Competizioni</h1>
          <p>
            RESISTANCE CALISTHENICS ENDURANCE ITALIA RICONOSCE PER LA STAGIONE
            AGONISTICA 2024 LE SEGUENTI TIPOLOGE DI GARE:
          </p>
          <div className='links'>
            <Link to='#nord' className='btn'>
              Campionato Italiano
            </Link>
            <Link to='#assoluto' className='btn'>
              Assoluto Italia
            </Link>
            <Link to='#premi' className='btn'>
              Premi
            </Link>
          </div>
        </div>
        <div className='description'>
          <h2>Campionato Italiano Endurance Calisthenics</h2>
          <p>
            il campionato italiano darà la possibilità di iscriversi a due
            differenti tipologie di competizioni:{' '}
            <b>Gara Completa e Gara Standard</b>
          </p>
          <section className='recapGare'>
            <div className='completa'>
              <h3>Gara Completa</h3>
              <div className='list'>
                <p>Max Rep (mu, dip, pull, push)</p>
                <p>Battle 1vs1</p>
                <p>Alzate valide per i Record Italiani</p>
              </div>
              <hr />
              <p>Qualificante per Assoluto Italia</p>
            </div>
            <div className='standard'>
              <h3>Gara Standard</h3>
              <div className='list'>
                <p>Max Rep (pull & push)</p>
                <p>Battle 1vs1</p>
              </div>
              <hr />
              <p>Non qualificante per Assoluto Italia</p>
            </div>
          </section>
          <p>
            Le gare saranno strutturate durante l'anno in tre date differenti
            ciascuna della durata di due giorni e si troveranno al nord, al
            centro e al sud Italia.
          </p>
          <p>
            L'iscrizione dell'atleta agli eventi Resistance è esclusivamente
            valida all'accesso della Gara Max Rep. Ogni altro format di gara
            sar&agrave; disponibile solo per gli atleti che supereranno la Gara
            Max Rep.
          </p>

          <h4>
            La seguenti modalità saranno replicate per tutte e tre le date:
          </h4>
          <p>
            La prima giornata prevederà la divisione per classe di peso e sesso,
            successivamente inizierà la Gara Max Rep sia per la Gara Completa
            che per la Gara Standard. <br /> La prima giornata terminerà con la
            Gara Battle 1vs1 della Competizione Standard, mentre la Gara Battle
            1vs1 della Competizione Completa si svolgerà durante la seconda
            giornata. <br /> &Egrave; prevista la divisione di peso per entrambe
            le tipologie di competizione.
          </p>

          <h4>Donna</h4>
          <ul>
            <li>Peso Paglia / -55kg</li>
            <li>Peso Piuma / +55kg</li>
          </ul>
          <h4>Uomo</h4>
          <ul>
            <li>Pesi Leggeri / -70kg</li>
            <li>Pesi Medi / -80kg</li>
            <li>Pesi Massimi / +80kg</li>
          </ul>
          <div className='links'>
            <Link to='#completa' className='btn'>
              Gara Completa
            </Link>
            <Link to='#standard' className='btn'>
              Gara Standard
            </Link>
          </div>

          <h4 id='completa'>Gara Completa</h4>
          <p>
            Gli atleti andranno nell’ordine assegnato dallo staff all'interno di
            ogni classe di peso e svolgeranno le prove di gara. Il primo format
            (Max Rep) prevede l'esecuzione di 4 esercizi standardizzati, in
            ordine muscle up alla sbarra, dip alle parallele, pull up alla
            sbarra e push up alla sbarra. Ad ogni atleta sarà concesso n°1 set
            unbroken per esercizio. I set saranno giudicati dagli ufficiali
            preposti.
          </p>
          <p>
            Tra un esercizio e l'altro sarà concesso un solo minuto di pausa e
            una volta completati gli esercizi sarà calcolato il totale del
            singolo atleta sommando tutte le ripetizioni ritenute valide dai
            giudici ottenute nelle diverse alzate.
          </p>
          <p>
            Per le categorie femminili il muscle up è opzionale quindi non è
            necessario fare almeno n°1 rep valida.
          </p>
          <p>
            I coefficienti di punteggio degli esercizi della fase max rep sono i
            seguenti: muscle up 2.5p, dip 1p, pull 1.5p, push up 1p. Tramite i
            punteggi ottenuti nella gara max rep verranno decretati n°12 atleti
            maschi (quattro per ogni classe di peso) e n°8 atlete femmine
            (quattro per ogni classe di peso) che accederanno alla seconda
            giornata. Al termine della prima giornata vi sarà la consegna dei
            premi inerenti alla Gara Max Rep.
          </p>
          <div className='links'>
            <Link className='btn' to='../best'>
              Vai ai record
            </Link>
          </div>
          <p>
            Nella seconda giornata gli atleti selezionati verranno abbinati ad
            un avversario a seconda del totale realizzato nella gara max rep
            sempre rispettando gli abbinamenti per classe di peso, il totale
            maggiore realizzato verra accoppiato con il totale minore realizzato
            all'interno della propria classe di peso. La gara battle 1vs1 sara
            divisa in due fasi:
          </p>
          <ol>
            <li>
              Nella prima fase gli atleti si sfideranno in un circuito a
              sorpresa ad eliminazione diretta contro il loro avversario. Passa
              il turno chi chiude per primo il circuito. Il muscle up e
              l'handstand push up non saranno presenti per le categorie
              femminili in questa sezione di gara.
              <br />
              Gli esercizi ufficiali da circuito per la gara completa sono:
              <ul>
                <li>muscle up</li>
                <li>pull up</li>
                <li>dips</li>
                <li>bar dips</li>
                <li>bar push up </li>
                <li>handstand push up</li>
                <li>air squat</li>
                <li>squat box jump</li> <li>jump squat</li>
              </ul>
              Saranno presenti obbligatoriamente in questa fase per uomo 4
              esercizi di upper e 1 di lower, mentre per donna 3 di upper e 1 di
              lower.
            </li>
            <li>
              Nella seconda fase si scontreranno i due vincitori di ogni battle
              per la propria classe di peso tra di loro, mentre chi avrà perso
              lo scontro si affronterà per il terzo posto del podio della sua
              classe.
              <br />
              La fase prevede che si completi un ulteriore circuito a sorpresa
              ad eliminazione diretta.
              <br />
              In questa fase il muscle up sarà obbligatorio per gli uomini in
              aggiunta a 3 esercizi di upper e 1 esercizio di lower.
              <br />
              In questa fase il pull up sar&agrave; obbligatorio per le donne in
              aggiunta a 2 esercizi di upper e 1 esercizio di lower.
              <br />I vincitori di questa fase decreteranno il piazzamento sul
              podio della classe di peso e tutti gli atleti sul podio otterrano
              la PRO CARD di qualifica per l'Assoluto Italia. Il Podio delle
              Battle decreterà la conclusione della Tappa.
            </li>
          </ol>
          <div className='links'>
            <Link className='btn' to='#premi'>
              Vai ai premi
            </Link>
            <Link className='btn' to='../hallOfFame'>
              VAI alle PRO CARD
            </Link>
          </div>

          <h4 id='standard'>Gara Standard</h4>
          <p>
            Gli atleti andranno nell’ordine assegnato dallo staff all'interno di
            ogni classe di peso e svolgeranno le prove di gara. Il primo format
            (Max Rep) prevede l'esecuzione di 2 esercizi standardizzati, in
            ordine pull up alla sbarra e push up alla sbarra. Ad ogni atleta
            sarà concesso n°1 set unbroken per esercizio. I set saranno
            giudicati dagli ufficiali preposti.
          </p>
          <p>
            Tra un esercizio e l'altro saranno concessi due minuti di pausa e
            una volta completati gli esercizi sarà calcolato il totale del
            singolo atleta sommando tutte le ripetizioni ritenute valide dai
            giudici ottenute nelle diverse alzate.
          </p>
          <p>
            La ripetizione del pull up verrà considerata 1.5p, mentre quella del
            push up 1p. Tramite i punteggi ottenuti nella gara max rep verranno
            decretati n°12 atleti maschi (quattro per ogni classe di peso) e n°8
            atlete femmine (quattro per ogni classe di peso) che accederanno
            alla seconda fase svolta sempre nella prima giornata di gara.
          </p>
          <p>
            Nella seconda fase gli atleti selezionati verranno abbinati ad un
            avversario a seconda del totale realizzato nella gara max rep sempre
            rispettando gli abbinamenti per classe di peso, il totale maggiore
            realizzato verra accoppiato con il totale minore realizzato
            all'interno della propria classe di peso. La gara battle 1vs1 sara
            divisa in due fasi:
          </p>
          <ol>
            <li>
              Nella prima fase gli atleti si sfideranno in un circuito a
              sorpresa ad eliminazione diretta contro il loro avversario. Passa
              il turno chi chiude per primo il circuito.
              <br />
              Gli esercizi ufficiali da circuito per la gara standard sono:
              <ul>
                <li>pull up</li>
                <li>dips</li>
                <li>bar push up </li>
                <li>air squat</li>
                <li>jump squat</li>
              </ul>
              Saranno presenti obbligatoriamente in questa fase 2 esercizi di
              upper e 1 esercizio di lower.
            </li>
            <li>
              Nella seconda fase si scontreranno i due vincitori di ogni battle
              per la propria classe di peso tra di loro, mentre chi avrà perso
              lo scontro si affronterà per il terzo posto del podio della sua
              classe.
              <br />
              La fase prevede che si completi un ulteriore circuito a sorpresa
              ad eliminazione diretta.
              <br />
              Gli esercizi presenti in questa fase, sia per uomo che per donna,
              saranno sempre 3 esercizi di upper e 1 di lower.
              <br />I vincitori di questa fase decreteranno il piazzamento sul
              podio della classe di peso della Gara Standard e per concludere la
              prima giornata di gara seguirà la premiazione Pull & Push
            </li>
          </ol>

          <div className='links'>
            <Link className='btn' to='../competizioni#premi'>
              Vai ai premi
            </Link>
          </div>

          <p>
            Con l'iscrizione all'evento l'atleta avrà l’accesso alla prima
            giornata (Max Rep) della gara per la quale ha acquistato il ticket.{' '}
            <br /> L’iscrizione del ticket sarà nominale e con la prima
            partecipazione ad un evento Resistance sarà obbligatorio anche
            pagare la copertura assicurativa che rilascia il tesserino tecnico
            ASI per poter gareggiare con noi 365 giorni. <br /> L’atleta non
            sarà vincolato a partecipare ad un unico evento del campionato
            italiano anche qualora egli si fosse già qualificato all’Assoluto
            Italia. Nel caso in cui l’atleta in questione dovesse iscriversi ad
            un'altra gara del campionato potrà prendere parte alla fase{' '}
            <b>Max Rep</b>, senza poter accedere alla fase <b>Battle1vs1</b>{' '}
            <br /> Questo poichè Resistance introdurrà i record italiani di
            endurance, consultabili nella pagina dei best ed un atleta sarà
            libero di partecipare alle competizioni presenti sia al nord che al
            centro e al sud per vincere i record rilasciati su tutto il suolo
            italiano. <br />
          </p>
          <p>
            Per ogni evento verrà stipulata una convenzione con hotel limitrofi
            alla zona della gara in questione, per agevolare l'atleta negli
            spostamenti.
          </p>

          <div className='links'>
            <Link className='btn' to={`../store/tickets/Nord`}>
              Acquista ticket atleta
            </Link>
          </div>

          <h4>ASSOLUTO ITALIA ENDURANCE CALISTHENICS</h4>
          <p>
            L’evento si terrà alla fine dell’anno e comprenderà i migliori 9
            pesi leggeri maschili, 9 pesi medi maschili, 9 pesi massimi maschili
            e le 6 migliori donne in assoluto, coloro quindi che hanno ottenuto
            una PRO CARD, ovvero i podi delle Battle 1vs1 della Gara Completa
            per ogni classe di peso delle tre diverse gare qualificanti del
            campionato italiano.
          </p>
          <p>
            L'Assoluto Italia è l'evento che decreterà il campione italiano di
            categoria maschile e la campionessa italiana assoluta. L'evento si
            chiuderà poi con la finale a tre in una Last Man Standing tra i
            vincitori maschili di categoria per stabilire il Campione Italiano
            Assoluto.
          </p>
          <ol>
            <li>
              <h5>FIRST SESSION - TIME BATTLE</h5>
              <p>
                Precedentemente alla gara in una sessione di streaming gestita
                tramite PRESS CONFERENCE mandata in onda 2 mesi prima
                dell’evento, l’atleta conoscerà il primo circuito
                anticipatamente; In questa fase sarà previsto un circuito a
                tempo dove si sfideranno tra di loro all’interno della classe di
                peso i 9 pesi leggeri, i 9 pesi medi, i 9 pesi massimi e le 6
                donne assolute. Vi saranno 5 eliminazioni da ogni classe per gli
                uomini e 2 eliminazioni per le donne. L’eliminazione terrà
                contro dell’ordine indicato tramite cronometro con la quale
                verrà completato il circuito andando 1 alla volta, quindi si
                qualificheranno i 4 miglior risultati per gli uomini da ogni
                classe e i 4 migliori per le donne. Gli esercizi in gara saranno
                quelli riconosciuti da regolamento per la gara completa REPZ-4.
                Nel circuito a sorpresa per l'uomo, saranno sempre presenti
                obbligatoriamente 4 esercizi di upper e 1 esercizio di lower.
                Per la donna, 3 di upper e 1 di lower.
              </p>
            </li>
            <li>
              <h5>SECOND SESSION - BATTLE 1vs1</h5>
              <p>
                Si scontreranno i 4 atleti di ogni categoria che hanno superato
                la TIME BATTLE, dove si affronteranno 1 contro 1 in un circuito
                ad eliminazione diretta. Gli abbinamenti verranno svolti
                basandosi sul tempo effettuato nella prima prova, dove il quarto
                tempo migliore si scontrerà con il primo, ed il secondo con il
                terzo. L’obiettivo di questa fase sarà quello di completare il
                circuito prima del diretto avversario. l'uomo, saranno sempre
                presenti obbligatoriamente 4 esercizi di upper e 1 esercizio di
                lower. Per la donna, 3 di upper e 1 di lower. Da questa fase
                usciranno gli abbinamenti per le finali.
              </p>
            </li>
            <li>
              <h5>THIRD SESSION – BATTLE 1vs1</h5>
              <p>
                Questa sessione prevede le finali per il titolo di categoria
                maschile e la finale assoluta femminile. I vincitori della fase
                precedente si scontreranno tra loro per il 1° e 2° posto mentre
                gli sconfitti per il 3° posto del podio di categoria. La fase
                prevede che si completi un numero di ripetizioni totali a
                sorpresa con struttura a blocchi di esercizi scelti tra i 9 già
                nominati in precedenza. Gli esercizi presenti obbligatoriamente
                saranno per gli uomini muscle up, 2 esercizi di upper e 1
                esercizio di lower. Per le donne, pullup, 1 esercizio di upper e
                1 esercizio di lower.
              </p>
            </li>
            <li>
              <h5>FOURTH SESSION – LAST MAN STANDING</h5>
              <p>
                La fase finale prevede la conquista del titolo assoluto maschile
                tra i campioni di categoria riconosciuti nella fase precedente.
                Si scontreranno dunque il campione pesi leggeri, medi e massimi
                in una finalissima a 3. Un circuito a sorpresa con un set fisso
                da eseguire unbroken ad oltranza, sempre composto di 3 esercizi
                tra i 9 possibili già visti. Gli esercizi previsti
                obbligatoriamente per gli uomini saranno muscle up e 2 esercizi
                di upper. Gli atleti si sfideranno a un “Last Man Standing”
                andando uno dopo l’altro con l’ordine di partenza deciso
                dall’atleta con il tempo migliore sul circuito della fase
                precedente; la rotazione prevede un recupero di 1’min tra i 3
                atleti. La sessione si interrompe quando 2 dei 3 sfidanti non
                saranno più in grado di completare il set unbroken. Resterà
                dunque l’ultimo uomo in piedi. Qualora 2 o tutti e 3 gli atleti
                stallassero alla stessa ripetizione dello stesso giro si
                deciderà il campione con un MAX REP finale di un esercizio a
                sorteggio tra: mu/dip/pull/push.
              </p>
            </li>
          </ol>
          <div className='links'>
            <Link className='btn' to='../hallOfFame'>
              Vai alle PRO CARD
            </Link>
            <Link className='btn' to={`../store/tickets/Assoluto`}>
              Acquista Ticket Spettatore VIP
            </Link>
          </div>

          <div className='record_premi' id='premi'>
            <h4>RECORD E PREMI</h4>
            <p>
              Le premiazioni avvengono sia per la <b>singola tappa</b> che per
              l’evento Finale. Per ogni voce si fa riferimento sia al{' '}
              <b>campo maschile</b> che rispettivamente al{' '}
              <b>campo femminile</b>.
            </p>
            <ol>
              <li>
                <span>CAMPIONE IN CARICA – ASSOLUTO ITALIA</span> <br />
                colui che si posizionerà per <b>primo</b> all’evento Finale
                dell’anno in corso nel <b>last man standing</b> e conquisterà la
                cintura assoluta ottenendo il <b>contratto atleta</b> esclusivo
              </li>
              <li>
                <span>PODIO ANNUALE – ASSOLUTO ITALIA</span>
                <br /> le prime <b>tre posizioni</b> all’evento finale dell’anno
                in corso nel <b>last man standing</b>
              </li>
              <li>
                <span>PODIO DELL'ASSOLUTO PER CLASSE DI PESO</span>
                <br />
                le prime <b>tre posizioni</b> nelle rispettive classi di peso
                all'evento finale dell'anno in corso
              </li>
              <li>
                <span>
                  PODIO DELLA TAPPA PER CLASSI DI PESO - CAMPIONATO ITALIANO -
                  GARA COMPLETA
                </span>
                <br /> le prime <b>tre posizioni</b> nelle rispettive classi di
                peso nella singola tappa, determinata alla fine delle Battle1vs1
                dopo la qualifica del Max Rep "mu, dip, pull, push"
              </li>
              <li>
                <span>
                  PODIO DELLA TAPPA PER CLASSI DI PESO - CAMPIONATO ITALIANO -
                  GARA STANDARD
                </span>
                <br /> le prime <b>tre posizioni</b> nelle rispettive classi di{' '}
                <b>peso</b> nella singola tappa, determinate alla fine delle{' '}
                <b>Battle1vs1</b> dopo la qualifica del Max Rep "pull & push"
              </li>
              <li>
                <span>PRO CARD - CAMPIONATO ITALIANO - GARA COMPLETA</span>
                <br />
                tessera atleta ottenuta solo dai tre podi di ogni classe di peso
                a fine Battle1vs1. Con la Pro Card si avrà l'accesso
                all'Assoluto Italia e si riceverà la T-shirt ufficiale atleta
              </li>
              <li>
                <span>
                  ASSOLUTI PER CATEGORIA D’ET&Agrave; DELLA TAPPA – CAMPIONATO
                  ITALIANO - GARA COMPLETA
                </span>
                <br />
                il miglior atleta nella propria categoria d’età verrà premiato
                al termine della prima <b>Gara – Max rep</b> di ogni singola
                tappa.
              </li>
              <li>
                <span>
                  RECORD DELLA TAPPA - CAMPIONATO ITALIANO - GARA COMPLETA
                </span>
                <br /> dopo la prima Gara – Max rep di ogni singola tappa
                vengono consegnati i record “<b>Best Reps</b>” suddivisi come
                segue:
                <ul>
                  <li>Miglior Totale assoluto</li>
                  <li>
                    Miglior Totale per classi di peso (leggeri, medi, massimi,
                    paglia, piuma)
                  </li>
                  <li>Miglior Totale Muscle ups</li>
                  <li>Miglior Totale Dips</li>
                  <li>Miglior Totale Pull ups </li>
                  <li>Miglior Totale Push ups</li>
                </ul>
              </li>
            </ol>
          </div>
        </div>

        <div className='tappa slide' id='nord' ref={refs[0]}>
          <img src={postNord} alt='' />
          <div className='content'>
            <h3>Campionato Italiano / Nord</h3>
            <Link
              className='indirizzo'
              to='https://maps.app.goo.gl/AFG9dAPurfgAAeLg6'
              target='_blank'
              rel='noreferrer'>
              <BiMap />
              <span>Upgrade Training Center </span> Via Po, 2, 20871 Torri
              Bianche MB
            </Link>

            {renderAcquista('Nord')}
          </div>
        </div>
        <div className='tappa slide' id='centro' ref={refs[1]}>
          <img src={postCentro} alt='' />
          <div className='content'>
            <h3>Campionato Italiano / Centro</h3>
            <Link
              className='indirizzo'
              to='https://maps.app.goo.gl/C46vr13r6z2Vdj466'
              target='_blank'
              rel='noreferrer'>
              <BiMap />
              <span>Heart Of Steel </span> Via Francesco Paolo Bonifacio, 151,
              00156 Roma
            </Link>
            {renderAcquista('Centro')}
          </div>
        </div>
        <div className='tappa slide' id='sud' ref={refs[2]}>
          <img src={postSud} alt='' />
          <div className='content'>
            <h3>Campionato Italiano / Sud</h3>
            <Link
              className='indirizzo'
              to='https://maps.app.goo.gl/GaBTD2UeQkKsnP7Z9'
              target='_blank'
              rel='noreferrer'>
              <BiMap />
              <span>Via Michele Cifarelli, 28, 70124 Bari BA</span>
            </Link>
            {renderAcquista('Sud')}
          </div>
        </div>
        <div id='assoluto' className='slide' ref={refs[3]}>
          <img src={isMobile ? assolutoMobile : assolutoDesktop} alt='' />
          <div className='content'>
            <p>
              <b>Biglietto VIP, ticket limitati. </b>
              <br /> Include un posto a sedere nominale e una t-shirt
              esclusiva stagione 2024
            </p>
            <Link
              className='indirizzo'
              to='https://maps.app.goo.gl/WMPPqpseUFQT67AN6'
              target='_blank'
              rel='noreferrer'>
              <BiMap />
              <span>Ironfit Monza</span> Via Mentana, 17, 20900 Monza MB
            </Link>
            {renderAcquista('Assoluto')}
          </div>
        </div>
      </main>
    </>
  );
};

export default Competizioni;
