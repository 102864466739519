import React from 'react';
import ProductsCarousel from '../store/ProductsCarousel';
import { Link } from 'react-router-dom';

const Shop = () => {
  return (
    <section className='shop'>
      <div className='text'>
        <h2>Shop</h2>
        <p className='yellow'>CHAMPIONSHIP, SPORTSWEAR AND EQUIPMENT</p>
      </div>
      <ProductsCarousel />
      <Link className='go-to' to='store'>
        Vai allo Shop
      </Link>
    </section>
  );
};

export default Shop;
