export const italianProvinces = [
  { name: 'Agrigento', abbreviation: 'AG' },
  { name: 'Alessandria', abbreviation: 'AL' },
  { name: 'Ancona', abbreviation: 'AN' },
  { name: 'Aosta', abbreviation: 'AO' },
  { name: 'Arezzo', abbreviation: 'AR' },
  { name: 'Ascoli Piceno', abbreviation: 'AP' },
  { name: 'Asti', abbreviation: 'AT' },
  { name: 'Avellino', abbreviation: 'AV' },
  { name: 'Bari', abbreviation: 'BA' },
  { name: 'Barletta-Andria-Trani', abbreviation: 'BT' },
  { name: 'Belluno', abbreviation: 'BL' },
  { name: 'Benevento', abbreviation: 'BN' },
  { name: 'Bergamo', abbreviation: 'BG' },
  { name: 'Biella', abbreviation: 'BI' },
  { name: 'Bologna', abbreviation: 'BO' },
  { name: 'Bolzano', abbreviation: 'BZ' },
  { name: 'Brescia', abbreviation: 'BS' },
  { name: 'Brindisi', abbreviation: 'BR' },
  { name: 'Cagliari', abbreviation: 'CA' },
  { name: 'Caltanissetta', abbreviation: 'CL' },
  { name: 'Campobasso', abbreviation: 'CB' },
  { name: 'Carbonia-Iglesias', abbreviation: 'CI' },
  { name: 'Caserta', abbreviation: 'CE' },
  { name: 'Catania', abbreviation: 'CT' },
  { name: 'Catanzaro', abbreviation: 'CZ' },
  { name: 'Chieti', abbreviation: 'CH' },
  { name: 'Como', abbreviation: 'CO' },
  { name: 'Cosenza', abbreviation: 'CS' },
  { name: 'Cremona', abbreviation: 'CR' },
  { name: 'Crotone', abbreviation: 'KR' },
  { name: 'Cuneo', abbreviation: 'CN' },
  { name: 'Enna', abbreviation: 'EN' },
  { name: 'Fermo', abbreviation: 'FM' },
  { name: 'Ferrara', abbreviation: 'FE' },
  { name: 'Firenze', abbreviation: 'FI' },
  { name: 'Foggia', abbreviation: 'FG' },
  { name: 'Forlì-Cesena', abbreviation: 'FC' },
  { name: 'Frosinone', abbreviation: 'FR' },
  { name: 'Genova', abbreviation: 'GE' },
  { name: 'Gorizia', abbreviation: 'GO' },
  { name: 'Grosseto', abbreviation: 'GR' },
  { name: 'Imperia', abbreviation: 'IM' },
  { name: 'Isernia', abbreviation: 'IS' },
  { name: 'La Spezia', abbreviation: 'SP' },
  { name: "L'Aquila", abbreviation: 'AQ' },
  { name: 'Latina', abbreviation: 'LT' },
  { name: 'Lecce', abbreviation: 'LE' },
  { name: 'Lecco', abbreviation: 'LC' },
  { name: 'Livorno', abbreviation: 'LI' },
  { name: 'Lodi', abbreviation: 'LO' },
  { name: 'Lucca', abbreviation: 'LU' },
  { name: 'Macerata', abbreviation: 'MC' },
  { name: 'Mantova', abbreviation: 'MN' },
  { name: 'Massa-Carrara', abbreviation: 'MS' },
  { name: 'Matera', abbreviation: 'MT' },
  { name: 'Medio Campidano', abbreviation: 'VS' },
  { name: 'Messina', abbreviation: 'ME' },
  { name: 'Milano', abbreviation: 'MI' },
  { name: 'Modena', abbreviation: 'MO' },
  { name: 'Monza e Brianza', abbreviation: 'MB' },
  { name: 'Napoli', abbreviation: 'NA' },
  { name: 'Novara', abbreviation: 'NO' },
  { name: 'Nuoro', abbreviation: 'NU' },
  { name: 'Ogliastra', abbreviation: 'OG' },
  { name: 'Olbia-Tempio', abbreviation: 'OT' },
  { name: 'Oristano', abbreviation: 'OR' },
  { name: 'Padova', abbreviation: 'PD' },
  { name: 'Palermo', abbreviation: 'PA' },
  { name: 'Parma', abbreviation: 'PR' },
  { name: 'Pavia', abbreviation: 'PV' },
  { name: 'Perugia', abbreviation: 'PG' },
  { name: 'Pesaro e Urbino', abbreviation: 'PU' },
  { name: 'Pescara', abbreviation: 'PE' },
  { name: 'Piacenza', abbreviation: 'PC' },
  { name: 'Pisa', abbreviation: 'PI' },
  { name: 'Pistoia', abbreviation: 'PT' },
  { name: 'Pordenone', abbreviation: 'PN' },
  { name: 'Potenza', abbreviation: 'PZ' },
  { name: 'Prato', abbreviation: 'PO' },
  { name: 'Ragusa', abbreviation: 'RG' },
  { name: 'Ravenna', abbreviation: 'RA' },
  { name: 'Reggio Calabria', abbreviation: 'RC' },
  { name: 'Reggio Emilia', abbreviation: 'RE' },
  { name: 'Rieti', abbreviation: 'RI' },
  { name: 'Rimini', abbreviation: 'RN' },
  { name: 'Roma', abbreviation: 'RM' },
  { name: 'Rovigo', abbreviation: 'RO' },
  { name: 'Salerno', abbreviation: 'SA' },
  { name: 'Sassari', abbreviation: 'SS' },
  { name: 'Savona', abbreviation: 'SV' },
  { name: 'Siena', abbreviation: 'SI' },
  { name: 'Siracusa', abbreviation: 'SR' },
  { name: 'Sondrio', abbreviation: 'SO' },
  { name: 'Taranto', abbreviation: 'TA' },
  { name: 'Teramo', abbreviation: 'TE' },
  { name: 'Terni', abbreviation: 'TR' },
  { name: 'Torino', abbreviation: 'TO' },
  { name: 'Trapani', abbreviation: 'TP' },
  { name: 'Trento', abbreviation: 'TN' },
  { name: 'Treviso', abbreviation: 'TV' },
  { name: 'Trieste', abbreviation: 'TS' },
  { name: 'Udine', abbreviation: 'UD' },
  { name: 'Varese', abbreviation: 'VA' },
  { name: 'Venezia', abbreviation: 'VE' },
  { name: 'Verbano-Cusio-Ossola', abbreviation: 'VB' },
  { name: 'Vercelli', abbreviation: 'VC' },
  { name: 'Verona', abbreviation: 'VR' },
  { name: 'Vibo Valentia', abbreviation: 'VV' },
  { name: 'Vicenza', abbreviation: 'VI' },
  { name: 'Viterbo', abbreviation: 'VT' },
];
