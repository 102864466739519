import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getUserDetails, logout } from '../../redux/actions/userActions';

import { BsFillGearFill } from 'react-icons/bs';
import { Link } from 'react-router-dom';

import Protected from '../../components/Protected';
import Title from '../../components/Title';
import UserOrders from '../../components/account/UserOrders';
import UserTickets from '../../components/account/UserTickets';

const Account = () => {
  const dispatch = useDispatch();
  const { user, loading, error } = useSelector((state) => state.userDetails);

  useEffect(() => {
    dispatch(getUserDetails());
  }, [dispatch]);

  const renderExpireDate = (date) => {
    const expireDate = new Date(date);
    const year = expireDate.getFullYear();
    const month = ('0' + (expireDate.getMonth() + 1)).slice(-2);
    const day = ('0' + expireDate.getDate()).slice(-2);
    return `${day}/${month}/${year} `;
  };

  return (
    <>
      <Title title={user?.info?.first_name || 'Account'} />
      <main className='accountPage'>
        <Protected />
        {loading && <div className='spinner'></div>}
        {error && <p className='appError'>{error}</p>}
        {user && (
          <>
            <section className='head'>
              <Link onClick={() => dispatch(logout())} to='../login'>
                Logout
              </Link>
              <div className='accountInfo'>
                <section className='settings'>
                  <Link to='../account/settings/profile'>
                    <BsFillGearFill />
                  </Link>
                </section>
              </div>
            </section>
            <section className='userInfo'>
              <h2>
                {user.info.first_name} {user.info.last_name}
              </h2>
            </section>
            <section className='insurance'>
              <h3>Assicurazione</h3>
              {user?.insurance ? (
                <>
                  <p>
                    Intestatario:{' '}
                    <span>
                      {user.insurance.nome} {user.insurance.cognome}
                    </span>
                  </p>
                  <p>
                    Codice Fiscale: <span>{user.insurance.codice_fiscale}</span>
                  </p>
                  <p>
                    Scadenza:{' '}
                    <span>{renderExpireDate(user.insurance.expire_date)}</span>
                  </p>
                </>
              ) : (
                <>
                  <p>Non hai assicurazioni attive.</p>{' '}
                  <p>Provvedi ad acquistarne una con il prossimo biglietto.</p>
                  <Link to='../store/' className='btn'>
                    Acquista un biglietto
                  </Link>
                </>
              )}
            </section>
            <UserTickets />
            <UserOrders />
          </>
        )}
      </main>
    </>
  );
};

export default Account;
