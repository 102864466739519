import React from 'react';
import { useSelector } from 'react-redux';
import { Link, Navigate } from 'react-router-dom';

import Title from '../../components/Title';
import SignUpForm from '../../components/account/auth/SignUpForm';

const SignUp = () => {
  const { user, userLoading } = useSelector((state) => state.userLogin);

  return (
    <>
      <Title title='Registrati' />
      <main className='signUp'>
        {user && <Navigate to='../account/' />}
        {userLoading && <div className='spinner'></div>}
        <section className='title'>
          <h1>Benvenuto su Resistance</h1>
          <small>
            Crea un account per vivere al 100% l'<b>Esperienza Resistance</b>!
            <br />
            Così potrai acquistare anche il tuo <b>Biglietto</b>
          </small>
        </section>
        <SignUpForm />
        <p className='login'>
          Hai già un account? <Link to='../login'>Accedi</Link>
        </p>
      </main>
    </>
  );
};

export default SignUp;
