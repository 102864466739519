import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import { updatePassword } from '../../../redux/actions/userActions';

const UpdatePassword = ({ user }) => {
  const dispatch = useDispatch();
  const [isPasswordUpdated, setIsPasswordUpdated] = useState(false);
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();

  const { message, error, loading } = useSelector(
    (state) => state.updateAccount
  );

  const onSubmit = async (formData) => {
    dispatch(updatePassword(formData));
    setIsPasswordUpdated(true);
  };
  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        {!loading && message && isPasswordUpdated && (
          <div className='passwordSuccessScreen'>
            <h2>Password aggiornata</h2>
          </div>
        )}
        {(!isPasswordUpdated || error) && (
          <>
            <section className={`${errors.oldPassword ? 'wrong' : ''}`}>
              <input
                type='password'
                {...register('oldPassword', {
                  required: 'Campo obbligatorio',
                  minLength: { value: 8, message: 'Password troppo corta' },
                })}
                id='oldPassword'
                pb-role='current_password'
                autoComplete='on'
                placeholder=' '
              />
              <label htmlFor='oldPassword'>Password</label>

              {errors.oldPassword && (
                <p className='error'>{errors.oldPassword.message}</p>
              )}
              <Link to='/auth/restore_password' className='forgotPassword'>
                Password dimenticata?
              </Link>
            </section>

            {/* New Password */}
            <section className={`${errors.newPassword ? 'wrong' : ''}`}>
              <input
                type='password'
                {...register('newPassword', {
                  required: 'Campo obbligatorio',
                  minLength: {
                    value: 8,
                    message: 'Nuova password troppo corta',
                  },
                })}
                id='newPassword'
                placeholder=' '
                pb-role='new_password'
              />
              <label htmlFor='newPassword'>Nuova Password</label>

              {errors.newPassword && (
                <p className='error'>{errors.newPassword.message}</p>
              )}
            </section>

            {/* Confirm Password */}
            <section className={`${errors.confirmPassword ? 'wrong' : ''}`}>
              <input
                type='password'
                {...register('confirmPassword', {
                  required: 'Campo obbligatorio',
                  minLength: { value: 8, message: 'Password troppo corta' },
                  validate: (value) =>
                    value === watch('newPassword') ||
                    'Le password non corrispondono',
                })}
                id='confirmPassword'
                placeholder=' '
                pb-role='confirm_password'
              />
              <label htmlFor='confirmPassword'>Conferma nuova password</label>

              {errors.confirmPassword && (
                <p className='error'>{errors.confirmPassword.message}</p>
              )}
            </section>

            <input type='submit' value='Aggiorna' className='btn green' />
          </>
        )}
        {/* Old Password */}
      </form>
    </>
  );
};

export default UpdatePassword;
