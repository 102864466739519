import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getCart } from '../../redux/actions/storeActions';
import CartItem from '../../components/store/CartItem';
import Title from '../../components/Title';
import Price from '../../components/store/Price';
import { Link } from 'react-router-dom';
import { AiFillThunderbolt } from 'react-icons/ai';
import TicketItem from '../../components/store/TicketItem';

const Cart = () => {
  const dispatch = useDispatch();
  const { products, tickets, loading, error } = useSelector(
    (state) => state.cart
  );

  useEffect(() => {
    dispatch(getCart());
  }, [dispatch]);

  const renderCart = (products) => {
    return products.map((item) => {
      return <CartItem key={item.product.id} item={item} />;
    });
  };

  const renderTickets = (tickets) => {
    return tickets?.map((ticket) => (
      <TicketItem key={ticket.id} ticket={ticket} />
    ));
  };

  const calculateTotal = (products, tickets) => {
    let total = 0;

    total += products?.reduce(
      (sum, { product: { price, discount }, quantity }) =>
        sum +
        (discount?.percent
          ? price - price * discount.percent
          : discount?.amount
          ? price - discount.amount
          : price) *
          quantity,
      0
    );

    total += tickets?.reduce(
      (sum, ticket) => sum + (ticket?.price + (ticket?.insurance ? 30 : 0)),
      0
    );

    return total;
  };

  return (
    <>
      <Title title='Carrello' />
      <main className='cartPage'>
        <h1>Il Tuo Carrello</h1>

        {error && <h2 className='appError'>{error}</h2>}
        {loading && <div className='spinner'></div>}
        {products?.length <= 0 && tickets?.length <= 0 && (
          <section className='emptyCart'>
            <h2>Il tuo carrello è vuoto</h2>
            <Link to='../store' className='btn'>
              Torna allo store
            </Link>
          </section>
        )}
        {(products?.length > 0 || tickets?.length > 0) && (
          <>
            <div className='tableTitles'>
              <p className='product'>Prodotto</p>
              <p>Prezzo</p>
              <p>Quantità</p>
              <p className='totalTitle'>Totale</p>
              <p>Rimuovi</p>
            </div>
            <hr />
          </>
        )}
        {products && renderCart(products)}

        {tickets && (
          <section className='ticket'>{renderTickets(tickets)}</section>
        )}
        {(products?.length > 0 || tickets?.length > 0) && (
          <div className='total'>
            <div className='separator'>
              <AiFillThunderbolt className='thunder' />
              <hr />
            </div>
            <Price amount={calculateTotal(products, tickets)} />
            <small>costi di spedizione esclusi calcolati al checkout</small>
          </div>
        )}
        {(products?.length > 0 || tickets?.length > 0) && (
          <div className='cartFooter'>
            <Link to='../store' className='returnStore'>
              Ritorna allo store
            </Link>
            {calculateTotal(products, tickets) > 0 && (
              <Link className='btn checkout' to='../store/checkout'>
                Checkout
              </Link>
            )}
          </div>
        )}
      </main>
    </>
  );
};

export default Cart;
