import React from 'react';

import { Link, Navigate, useLocation } from 'react-router-dom';

import Title from '../../components/Title';

import LoginForm from '../../components/account/auth/LoginForm';
import { useSelector } from 'react-redux';

const Login = () => {
  const location = useLocation();
  const redirectLocation = location.search
    ? location.search.split('=')[1]
    : '../account';

  const { user } = useSelector((state) => state.userLogin);

  return (
    <>
      <Title title='Login' />
      <main className='loginPage'>
        {user && <Navigate to={`${redirectLocation}`} />}
        <section className='title'>
          <h1>Benvenuto</h1>
          <small>
            Entra in <b>Resistance</b> per acquistare il tuo <b>Biglietto</b>
          </small>
        </section>
        <LoginForm />
        <p className='register'>
          Non hai ancora un account? <Link to='../signup'>Registrati</Link>
        </p>
      </main>
    </>
  );
};

export default Login;
