// React & React Router Dom
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

// Immagini
import Logo from '../images/logo1000.png';

// Components
import Title from '../components/Title';

// Layout
import Partners from '../layout/home/Partners';
import Countdown from '../layout/home/Countdown';
import Social from '../layout/home/Social';
import Shop from '../layout/home/Shop';
import Events from '../layout/home/Events';

const Home = () => {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <>
      <Title title='Home' />
      <main className='homePage'>
        <section className='head'>
          <img className='logo' src={Logo} alt='' />
          <p className='scintilla'>Sprigiona la tua scintilla</p>
          <Countdown />
          <p className='campionato'>
            <span className='gara'>Gara Sud</span> <br />{' '}
            <span className='green'> Campionato </span>
            <span className='white'> Italiano</span>
            <span className='red'> Endurance </span>
          </p>
          <Link className='btn' to={`../store/tickets/Sud`}>
            Acquista il tuo biglietto
          </Link>
        </section>

        <Partners />
        <Events isMobile={isMobile} />
        <Shop />
        <Social />
      </main>
    </>
  );
};

export default Home;
